import React, { useEffect, useLayoutEffect, useState } from 'react';
import axios from 'axios'
import { makeStyles, useTheme } from "@material-ui/core/styles";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
// import { makeStyles } from '@material-ui/styles';
import queryString from 'query-string';

import { Grid } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import PropTypes from 'prop-types';
import DirectionsIcon from '@material-ui/icons/Directions';
import httpUtils from '../../common/httpUtils'
import moment from 'moment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloudOffIcon from '@material-ui/icons/CloudOff';
// import LocalParkingIcon from '@material-ui/icons/LocalParking';
import { withStyles } from "@material-ui/core/styles";
import QrReader from "react-qr-reader";
import { useLocation } from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import LocalParkingOutlinedIcon from '@material-ui/icons/LocalParkingOutlined';
import ReplayIcon from '@material-ui/icons/Replay';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import useClipboard from 'react-hook-clipboard'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import DialogActions from '@material-ui/core/DialogActions';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Avatar,
  Checkbox,
  Table,
  TextField,
  TableBody,

  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  CardActionArea
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(0),
  },
  paper: {
    // padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 'auto',
    backgroundColor: "white"
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'top ',
    // lineHeight:'px',
    // marginLeft:"5px"
  },
  createdAt: {
    marginLeft: "8px",
    color: "rgb(0,109,53)",
    fontSize: "20px",
    marginTop: "4px"

  }, date: {
    marginLeft: '58px',
    lineHeight: '0px',
    marginBottom: "20px",
    fontSize: '13px',
    marginTop: "10px"



  },
  date1: {
    marginLeft: '58px',
    lineHeight: '0px',
    marginBottom: "20px",
    fontSize: '13px',
    marginTop: "-6px"
  },
  minimizeScreen: {
    ['@media (min-width:380px)']: {
      marginTop: '15px'
    },
    ['@media (max-width:379px)  and (max-height:646px)']: {
      marginTop: '0px'
    },
    ['@media (max-width:379px) and (min-height:645px)']: {
      marginTop: '15px'
    }
  },
  minimizefontSize: {
    ['@media (min-width:380px)']: {
      fontSize: '14px'
    },
    ['@media (max-width:379px)']: {
      fontSize: '13px'
    },
  },
  bottom_but: {
    bottom: '20px',
    // position: 'absolute',
    ['@media (max-width:330px) and (min-height:640px)']: {
      marginTop: '15px',
      bottom: '0px!important',
      position: 'unset!important',
    },
    ['@media (min-width:445px) and (min-height:640px) and (max-height:670px)']: {
      bottom: '0px!important',
      position: 'unset!important',
      marginTop: '15px'
    },
    ['@media (max-height:600px) and (max-width:330px)']: {
      position: 'unset!important',
      marginTop: '15px',
      bottom: '0px!important'
    },
    ['@media(min-width:350px) and (max-width:440px)']: {
      position: 'absolute'
    },
    ['@media(min-width:381px) and (max-width:444px) and (min-height:545px) and (max-height:578px)']: {
      position: 'unset!important',
      marginTop: '15px',
      bottom: '0px!important'
    },
    ['@media(min-width:320px) and (max-width:600px) and (min-height:600px) and (max-height:960px)']: {
      position: 'absolute',
      bottom: '3px'
    }
  },
  suc_btn:{
    backgroundColor: "rgb(0,109,53)", color: "white"
  },
  def_btn:{
    color:'gray!important'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: 'rgba(0,0,0,0.3)'
  }
}));

const Dashboard = props => {
  const { history } = props;
  // console.log(history)
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [opencircular, setopencircular] = React.useState(false);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  var bid = (localStorage.getItem('bid'))
  const [callLogs, setcallLogs] = useState(false)
  const [openCopied, setOpenCopied] = React.useState(false);
  if (!bid) {
    var bid = (location.state.detail)
    localStorage.setItem('bid', bid)

  }
  const handleCloseCopied = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenCopied(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleClickOpen = () => {
    if (isIOS) {
      setOpenWarning(true)
    }
    else {
      setopencircular(true)
      // setOpenWarning(true)
      httpUtils({
        method: "GET",
        url: "/v1/userConfigByBID/" + bid

      }).then((response) => {
        if (response.status) {
          setopencircular(false)
          if (response['data']['state']['state'] == '2') {
            history.push('/estimate')
          }
          else {
            setshowCameraPermissionError(false)
            setcallLogs(true)
            setTimeout(() => {
              // console.log('close')
              httpUtils({
                method: "POST",
                url: "/webview/logs",
                data: { "bid": bid, "message": "Qr code not scanned from last 30 sec in dashboard route." }
              }).then(response => {
              }).catch(err => {
                console.log(err)
              })
              setOpenDialog(false);
            }, 32000)
            setOpenDialog(true);
            // console.log('called')
          }
        }
      })



    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setfailedcancel(false)
    setOpen(false);
  };
  const handleCloseWarning = () => {
    setOpenWarning(false);
  }
  // console.log(window.navigator.onLine)
  const [mode, setmode] = useState(true);
  const [amount, setamount] = useState('');
  const theme = useTheme();
  const [starttime, setstarttime] = useState('');
  const [endtime, setendtime] = useState('');
  const [currency, setcurrency] = useState('');
  var input = '';
  const [ratetable, setratetable] = useState('')
  const [garagename, setgaragename] = useState('');
  const [address, setaddress] = useState('');
  const [second_addr, setsecond_addr] = useState('');
  var reEnter = 0
  const [isIOS, setisIOS] = useState(false);
  const [openCancel, setopenCancel] = useState(false)
  const [showCancelBooking, setshowCancelBooking] = useState(false)
  const [cancelmessage, setcancelmessage] = useState('');
  const [cancelbody, setcancelbody] = useState('');
  const [failedcancel, setfailedcancel] = useState(false);
  const [failuretext, setfailuretext] = useState('')
  const [header, setheader] = useState('')
  const [yesclicked, setyesclicked] = useState(true)
  const [errormessage, seterrormessage] = useState('')
  const [openWarning, setOpenWarning] = React.useState(false);
  const [showCameraPermissionError, setshowCameraPermissionError] = useState(false)
  const [showIOSCameraLink, setshowIOSCameraLink] = useState(false)
  // const [clipboard, copyToClipboard] = useClipboard()
  const [changeCancellationPolicyHotel, setchangeCancellationPolicyHotel] = React.useState(false)
  const [jsonArr, setjsonArr] = React.useState({})
  const copyurl = (window.location.origin)
  const [openValidateCode, setOpenValidateCode] = useState(false)
  // console.log(copyurl)
  const toClipboard = copyurl + '?PIA=' + bid + '&'
  const [validateCode, setValidateCode] = useState("");
  // console.log(toClipboard)
  const parsed = queryString.parse(props.location.search);
  if (parsed.reEnter == 1) {
    reEnter = 1
  }
  else {
    reEnter = 0
  }
  const handleCopy = (value) => {
    // console.log('click')
    // copyToClipboard(toClipboard)
    navigator.clipboard.writeText(toClipboard, null, '\t')
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setOpenCopied(true)
  }
  const handleOpenbrowser = () => {
    // console.log('open')
    window.location.href = "x-web-search://";
    setOpenWarning(false)
    // window.open('http://www.google.com')
  }

  const tutorialSteps = [
    {
      no: "Step 1:",
      buttonText: "Copy",
      func: handleCopy,
      label: "Tap on the button to copy the reservation link",
    },
    {
      no: "Step 2:",
      buttonText: "Open Browser",
      func: handleOpenbrowser,
      label: "Tap on the button below to open Safari and paste the copied link",

    }
  ]
  const maxSteps = tutorialSteps.length;



  const handleCancelBooking = () => {
    setopencircular(true)
    httpUtils({
      method: 'get',
      url: '/v1/booking/cancel/charges/' + bid
    }).then((response) => {
      // console.log(response)
      setopencircular(false)
      if (response.status) {
        setopenCancel(true);

        setcancelmessage(response.message)
        setcancelbody(response.data['body'])

      }
      else {
        setfailedcancel(true)

        seterrormessage(response.message)
        setOpen(true);

      }

    })

  };
  const handleCancelClose = () => {
    setopenCancel(false);
    setyesclicked(true)
  };

  useLayoutEffect(() => {
    // let url = "https://pialleyapi-dev.divrt.co/api/v1/userConfigByBID/" + bid

    httpUtils({
      method: "GET",
      url: "/v1/userConfigByBID/" + bid + '?reEnter=' + reEnter,

    }).then((response) => {
      if (response.status) {
        if (response['data']['state']['state'] == '8' || response['data']['state']['state'] == '10') {
          // console.log('based on state')
          history.push('/thankyou')
        }
        else if (response['data']['state']['state'] == '2') {
          history.push('/estimate')
        }
        if (response['data']['state']['state'] == '-1') {
          history.push('/not-found')
        }
        if (response['data']['state']['state'] == '-99') {
          history.push('/cancelled')
        }
        if (response['data']['state']['state'] == '99') {
          history.push('/cancelled')
        }
        var starttimestamp = parseInt(response['data']['state']['startTimestamp'])

        var current = Math.floor((new Date()).getTime() / 1000)
        var offset1 = parseInt(response['data']['state']['zone']['timezonedstoffset'])
        var offset2 = parseInt(response['data']['state']['zone']['timezoneoffset'])
        var current_offsets = parseInt(current) + offset1 + offset2
        // console.log(parseInt(current_offsets))
        // if (starttimestamp > current_offsets) {
        if (response['data']['state']['cancelationAllowed'] == '1') {
          setshowCancelBooking(true)
        } else {
          setshowCancelBooking(false)
        }
        if (response['data']['state']['booking_type'] == '3') {
          setchangeCancellationPolicyHotel(true)
        }
        setheader(response['data']['state']['middleHeader'])
        setamount(response['data']['state']['amount'])
        setratetable(response['data']['state']['zone']['rate_link'])
        setcurrency(response['data']['state']['zone']['currency'])
        // setstarttime(moment(response['data']['state']['startTime']).format('MMMM Do hh:mm A'))
        // setendtime(moment(response['data']['state']['endtime']).format('MMMM Do hh:mm A'))
        setjsonArr(response['data']['jsonArr'][0])
        setstarttime(response['data']['state']['entry'])
        setendtime(response['data']['state']['exit'])
        setaddress(response['data']['state']['zone']['short_addr'])
        setgaragename(response['data']['state']['zone']['short_name'])
        setsecond_addr(response['data']['state']['zone']['addr_str2'])
        sessionStorage.setItem('garage_name', response['data']['state']['zone']['short_name'])
        sessionStorage.setItem('starttime', moment(response['data']['state']['startTime']).format('MMMM Do hh:mm A'))
        sessionStorage.setItem('endtime', moment(response['data']['state']['endtime']).format('MMMM Do hh:mm A'))
        sessionStorage.setItem('est_amt', (response['data']['state']['zone']['currency'] + response['data']['state']['amount']))

        localStorage.setItem('usersdata', JSON.stringify(response))
        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        // console.log(iOS)
        if (iOS) {
          // console.log('inIOS')
          let userAgentString =
            navigator.userAgent;
          let chromeAgent = userAgentString.indexOf("Chrome") > -1;
          // console.log(chromeAgent)
          // if (chromeAgent) {
          //   setisIOS(true)
          //   console.log('inloop')
          // }
          var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
          // console.log(isSafari)
          if (isSafari) {
            setisIOS(false)
            setshowIOSCameraLink(true)
            // console.log('keepasit')
          }
          else {
            setisIOS(true)
            setOpenWarning(true)
            // console.log('change')
          }
        }
        else {
          var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
          // console.log(isSafari)
          var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
          // console.log(isSafari)
          var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          if (isSafari && iOS) {
            console.log("You are using Safari on iOS!");
          }
          let userAgentString =
            navigator.userAgent;
          let chromeAgent = userAgentString.indexOf("Chrome") > -1;
          // console.log('inelse')
          // console.log(chromeAgent)

        }


      }

    }).catch(err => {
      setmode(false)
      let collect = localStorage.getItem('usersdata')
      var storeddata = JSON.parse(collect)
      // console.log('nodata')
    })

  }, [])

  const handleScan = data => {
    // console.log(data, new Date().toLocaleTimeString())
    if (!bid) {
      // Call logs API

      httpUtils({
        method: "POST",
        url: "/webview/logs",
        data: { "bid": "null", "message": "ID not present in the URL in dashboard page in handleScan" }
      }).then(response => {
        // console.log(response)

      })
      history.push('/not-found')
    }

    //store pop-up logs
    if (callLogs) {
      httpUtils({
        method: "POST",
        url: "/webview/logs",
        data: { "bid": bid, "message": "Success: Pop-up opened from dashboard route with camera permission" }
      }).then(response => {

        setcallLogs(false)
      })

    }

    setOpen(false);
    if (data) {
      input = data;
      // window.navigator.vibrate(200);
      setOpenDialog(false);
      setopencircular(true)
      // input = data;
      const send = { "bid": bid, "beaconId": input,"type":'QR' }
      httpUtils({
        method: "POST",
        url: "/v1/webCheckIn",
        data: send
      }).then(response => {
        input = ""
        setopencircular(false)
        if (response.status) {
          history.push('/estimate')
        }
        else {

          // response.message == "Invalid Booking ID" || 

          seterrormessage(response.message)
          setOpen(true);

        }

      }, (error) => {
        console.log(error);
      });
    }

  }
  const handleValidateCode = (e) => {
    // console.log(e.target.value)
    console.log(validateCode)
    setValidateCode(e.target.value)
  }
  const handleSendValidateCode = () => {
    console.log(validateCode)
    setOpenValidateCode(false);
    setopencircular(true)
    // input = data;
    const send = { "bid": bid, "beaconId": validateCode ,"type":'CODE'}
    httpUtils({
      method: "POST",
      url: "/v1/webCheckIn",
      data: send
    }).then(response => {
      input = ""
      setopencircular(false)
      if (response.status) {
        history.push('/estimate')
      }
      else {
        seterrormessage(response.message)
        setOpen(true);

      }

    }, (error) => {
      console.log(error);
    });
  }

  const handleError = err => {
    console.log(err)
    if (callLogs) {
      httpUtils({
        method: "POST",
        url: "/webview/logs",
        data: { "bid": bid, "message": "Failure: Pop-up didn't opened from dashboard route with no camera permission" }
      }).then(response => {
        // console.log(response)
        setcallLogs(false)
      })
      setshowCameraPermissionError(true)
      //  setOpenDialog(false);
    }
  }


  const CancelBooking = () => {
    setopencircular(true)
    httpUtils({
      method: 'POST',
      url: '/v1/booking/' + bid + '/cancel'
    }).then((response) => {
      // console.log(response)
      setopencircular(false)
      if (response['status']) {
        setyesclicked(true)
        history.push('/cancelled')
      }
      else {
        // console.log('not')
        setyesclicked(false)
        setfailuretext(response['message'])
      }

    }, (error) => {
      console.log(error)
    })




  }
  const handlereload = () => {
    window.location.reload()
  }
  const handleClickOpenValidateCode = () => {
    setOpenDialog(false);
    setValidateCode('')
    setOpenValidateCode(true);
  };

  const handleCloseValidateCode = () => {
    setOpenValidateCode(false);
  };

  return (

    <div className={classes.root}>

      {mode ? <Grid
        container
        spacing={0}
      >
        <Grid
          item
          lg={12}
          md={12}
          // xl={12}
          xs={12}
        >
          <div className={classes.paper}>
            <Grid container >

              <Grid container direction="row" spacing={0}>

                <Grid container direction="row" >
                  {/* <Typography variant="body2" gutterBottom> */}
                  <Grid container direction="column" xs={9} >
                    <Grid item className={classes.nameContainer} style={{ marginTop: "5px", marginLeft: '15px' }} >

                      <RoomIcon style={{ fontSize: 35, color: 'rgb(154,154,154)' }} />  <span className={classes.createdAt}> {garagename}</span>
                    </Grid>
                    <Grid item className={classes.date} gutterBottom style={{ color: "black" }} > {address} </Grid>
                    <Grid item className={classes.date1} gutterBottom style={{ color: "black" }} > {second_addr}</Grid>

                  </Grid>
                  {/* </Typography> */}
                  <Grid container direction="column" xs={3}>
                    <Grid item className={classes.nameContainer} style={{ marginTop: "25px" }} >
                      <Button component="a" target='_blank' style={{ marginLeft: '15px' }}
                        href="http://maps.google.com/maps?daddr=42.3581509,-71.0602557"> <DirectionsIcon style={{ fontSize: '40px' }} /></Button>
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container direction="row" spacing={0} className={classes.minimizeScreen}>
                  {/* <Grid item xs={12} style={{ textAlign: "center" }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, fontSize: '18px'}}>{header}</Typography>
                  </Grid> */}
                  {
                    Object.entries(jsonArr).map(([key, value]) => (
                      <Grid container direction="row" spacing={0} className={classes.minimizeScreen} key={key}>
                        <Grid item xs={5} style={{ textAlign: "left" }}>
                          <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '38px' }}>{key}:</Typography>
                        </Grid>
                        <Grid item xs={7} style={{ textAlign: "left" }}>
                          <Typography gutterBottom style={{ color: "black" }}>   {value}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  {/* <Grid item xs={5} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '38px' }}>Enter After:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black" }}>   {starttime}</Typography>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '38px', marginTop: '15px' }}>Exit By:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", marginTop: '15px' }}>{endtime}</Typography>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '38px', marginTop: '15px' }}>Amount Paid:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginTop: '15px' }}>{currency}{amount}</Typography>
                  </Grid> */}


                  {/* <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800 }}></Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800 }}></Typography>
                  </Grid> */}

                </Grid>

                <Grid container direction="row" className={classes.bottom_but} spacing={0}>
                  {showCancelBooking ? <Grid item xs={12}><Typography style={{ color: 'gray', textAlign: "center", fontSize: '17px' }} onClick={handleCancelBooking}>Cancel Reservation</Typography></Grid> : null}
                  <Grid item xs={12} style={{ textAlign: "center" }} >
                    {changeCancellationPolicyHotel ? <div>

                      <Typography style={{ color: "black", fontWeight: 800 }}>*Additional charges are applicable for early arrival and late checkout. The amount will be calculated while exiting.</Typography>
                      {/* <Typography gutterBottom style={{ color: "black", fontWeight: 800 }}></Typography> */}

                    </div> : <div>

                      <Typography className={classes.minimizefontSize} style={{ color: "black", fontWeight: 800 }}>*Additional charges
                    are applicable for late checkout.</Typography>
                      <Typography gutterBottom className={classes.minimizefontSize} style={{ color: "black", fontWeight: 800 }}> The amount will be calculated while exiting.</Typography>

                    </div>}
                    <hr style={{ height: '0px!important', color: 'rgb(232,232,232)!important', marginBottom: '5px', marginTop: '1px' }} />
                    <Button onClick={handleClickOpen} variant="contained" style={{ backgroundColor: "rgb(0,109,53)", color: "white", height: "55px", minWidth: "270px", fontSize: "20px", fontWeight: 100 }}> Scan to enter</Button>

                  </Grid>
                </Grid>
              </Grid>

            </Grid>

          </div>

          <Backdrop className={classes.backdrop} open={opencircular}>
            <CircularProgress style={{ color: "rgb(0,109,53)" }} />
          </Backdrop>


        </Grid>



        <Dialog onClose={handleClose} disableBackdropClick aria-labelledby="customized-dialog-title" open={open} >

          <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: 'crimson' }}>
            <ErrorOutlineIcon style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />

          </DialogTitle>
          <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>
            <DialogContent>
              <Grid style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ fontSize: '22px', fontWeight: 600, color: '#cc0000' }}>Oh Snap!</Typography>
                <Typography gutterBottom>

                  <p style={{ fontSize: '16px', marginTop: '20px', color: '#cc0000' }}>{errormessage}</p>

                  <div style={{ marginTop: '15px' }}><Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: 'crimson' }} onClick={handleClose}>Close</Button></div>
                </Typography>
                {setfailedcancel ? <Typography gutterBottom style={{ marginTop: "35px", textAlign: 'center', fontSize: "14px", color: 'gray' }}>
                  {changeCancellationPolicyHotel ? <a target='_blank' href='https://pialleygarage.com/hotel-cancellation-policy/'><u style={{ color: 'gray' }}>Read the cancellation policy here.</u></a>
                    : <a target='_blank' href='https://pialleygarage.com/cancellation-policy/'><u style={{ color: 'gray' }}>Read the cancellation policy here.</u></a>}
                </Typography> : null}

              </Grid>
            </DialogContent>
          </div>
        </Dialog>

        <Snackbar open={openCopied} autoHideDuration={1000} onClose={handleCloseCopied}>
          <Alert onClose={handleCloseCopied} severity="success">
            Copied
        </Alert>
        </Snackbar>

        <Dialog onClose={handleCloseWarning} aria-labelledby="customized-dialog-title" open={openWarning} >

          <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: 'darkorange' }}>
            <WarningRoundedIcon xs={12} style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />
            {/* <Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: 'darkorange' }} onClick={handleCloseWarning}> <CloseIcon  style={{ marginRight: '5px' }} /></Button> */}
          </DialogTitle>
          <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>
            <DialogContent>
              <Grid style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ fontSize: '22px', fontWeight: 600, color: 'darkorange' }}>App Compatibility Issue</Typography>
                <Typography gutterBottom>

                  <p style={{ fontSize: '16px', marginTop: '20px', color: 'darkorange' }}>Please follow these additional steps to open the link in Safari</p>

                  <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {tutorialSteps.map((step, index) => (
                      <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                          <div>
                            <Typography
                              gutterBottom
                              style={{
                                marginTop: "30px",
                                marginLeft: "10px",
                                fontSize: "20px",
                                fontWeight: 800,
                                color: "rgb(0,109,53)"
                              }}
                            >
                              {step.no}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                marginLeft: "10px",
                                fontWeight: 500,
                                color: "rgb(0,109,53)"
                              }}
                            >
                              {step.label}
                            </Typography>
                            <Button
                              onClick={step.func}
                              style={{
                                color: "white",
                                borderRadius: "16px",
                                marginTop: "10px",
                                width: "auto",
                                backgroundColor: "darkorange"
                              }}
                            >
                              {step.buttonText}
                            </Button>
                          </div>
                        ) :
                          null}
                      </div>
                    ))}
                  </SwipeableViews>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        Next
            {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
            Back
          </Button>
                    }
                  />


                  {/* <div style={{ marginTop: '15px' }}><Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: 'darkorange' }} onClick={handleCloseWarning}> <CloseIcon style={{ marginRight: '5px' }} />Close</Button></div> */}
                </Typography>

              </Grid>
            </DialogContent>
          </div>
        </Dialog>

        <Dialog
          disableBackdropClick
          open={openValidateCode}
          onClose={handleCloseValidateCode}
          aria-labelledby="alert-dialog-title"
        >
          <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}> <h2>Enter the code affixed to the gate</h2></DialogTitle>

            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                  // error={validateCode.length<1}
                    autoFocus
                    variant="outlined"
                    id="outlined-code"
                    autoComplete='off'
                    value={validateCode}
                    label="Enter Code"
                    type="text"
                    onChange={handleValidateCode}
                    fullWidth
                    // helperText={validateCode.length<1?'Empty field!':''}
                    margin="normal"
                    
                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center", marginTop: '30px' }}>
                  <Button onClick={handleSendValidateCode} disabled={validateCode.length<1} variant="contained" className={validateCode.length>=1?classes.suc_btn:classes.def_btn} style={{ width: "100px", fontSize: "18px", fontWeight: 100 }}>Send</Button>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center", marginTop: '15px' }}>
                  <Button onClick={handleCloseValidateCode} style={{ width: "100px", fontSize: "17px", fontWeight: 100 }}>Close</Button>
                </Grid>
              </Grid>
            </DialogContent>

          </div>
        </Dialog>
        <Dialog onClose={handleCancelClose} disableBackdropClick aria-labelledby="customized-dialog-title" open={openCancel}>
          <Backdrop className={classes.backdrop} open={opencircular}>
            <CircularProgress style={{ color: "rgb(0,109,53)" }} />
          </Backdrop>
          <DialogTitle id="customized-dialog-title" >
            <CloseIcon onClick={handleCancelClose} style={{ float: 'right', cursor: 'pointer' }} />

          </DialogTitle>
          <div style={{ minHeight: '400px', width: "290px" }}>
            <DialogContent>

              <Typography style={{ textAlign: 'center', fontSize: "16px", fontWeight: 800, color: 'black' }}>
                {cancelmessage}
              </Typography>

              <Typography gutterBottom style={{ marginTop: "50px", padding: '0px 10px', textAlign: 'center', fontSize: "20px", color: 'gray' }} >
                {cancelbody}
              </Typography>

              <Grid style={{ textAlign: "center", marginTop: '30px' }}>
                <Button onClick={CancelBooking} variant="contained" style={{ backgroundColor: "rgb(0,109,53)", color: "white", width: "100px", fontSize: "20px", fontWeight: 100 }}>YES</Button>
              </Grid>
              {yesclicked ? null : <Typography style={{ textAlign: 'center', fontSize: "15px", color: 'indianred', marginTop: "30px" }}>{failuretext}</Typography>}
              <Typography gutterBottom style={{ marginTop: "100px", textAlign: 'center', fontSize: "14px", color: 'gray' }}>
                {changeCancellationPolicyHotel ? <a target='_blank' href='https://pialleygarage.com/hotel-cancellation-policy/'><u style={{ color: 'gray' }}>Read the cancellation policy here.</u></a>
                  : <a target='_blank' href='https://pialleygarage.com/cancellation-policy/'><u style={{ color: 'gray' }}>Read the cancellation policy here.</u></a>}
              </Typography>


            </DialogContent>
          </div>

        </Dialog>


        <Dialog onClose={handleCloseDialog} disableBackdropClick aria-labelledby="customized-dialog-title" open={openDialog} >

          {showCameraPermissionError ? <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: 'crimson' }}>
            <ErrorOutlineIcon style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />

          </DialogTitle> :
            <DialogTitle id="customized-dialog-title" style={{ margin: '0px!important' }}>
              <CloseIcon onClick={handleCloseDialog} style={{ float: 'right', cursor: 'pointer' }} />

            </DialogTitle>}


          <DialogContent >
            {showCameraPermissionError ? <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>

              <Grid >
                <Typography gutterBottom style={{ textAlign: 'center', fontSize: '22px', fontWeight: 600, color: '#cc0000' }}>Oh Snap!</Typography>
                <Typography gutterBottom>

                  <p style={{ textAlign: 'center', fontSize: '16px', marginTop: '20px', color: '#cc0000' }}>Camera access is denied</p>
                  {showIOSCameraLink ? <Grid><Typography gutterBottom style={{ marginTop: "10px", fontSize: "14px", color: 'gray', textAlign: 'center' }}>Refresh the page and Select "Allow"</Typography>
                  </Grid> : null}
                  <div style={{ marginTop: '15px', textAlign: 'center' }}><Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: 'crimson' }} onClick={handleCloseDialog}>Close</Button></div>
                </Typography>
                {showIOSCameraLink ? <Typography gutterBottom style={{ marginTop: "35px", textAlign: 'center', fontSize: "14px", color: 'gray' }}>
                  <a target='_blank' href='https://support.apple.com/en-in/guide/iphone/iph168c4bbd5/ios'><u style={{ color: 'gray' }}>Read more to know how to allow the camera permission.</u></a>
                </Typography> : <Typography gutterBottom style={{ marginTop: "35px", textAlign: 'center', fontSize: "14px", color: 'gray' }}>
                  <a target='_blank' href='https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid&hl=en&oco=1'><u style={{ color: 'gray' }}>Read more to know how to allow the camera permission.</u></a>
                </Typography>}

              </Grid>
              {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: '15px' }}>
                <Button onClick={handleClickOpenValidateCode} style={{ fontSize: "16px", fontWeight: 800, color: 'rgb(25, 118, 210)' }}>
                  Enter via Code
                </Button>
              </Grid> */}
            </div> :
              <div>

                <QrReader

                  delay={200}
                  onError={handleError}
                  onScan={handleScan}
                  style={{
                    position: "initial!important",
                    border: "2px rgba(0,0,0,0.3)",
                    width: "310px",
                    height: "330px"
                  }}

                />


                <Grid container direction='row' >
                  <Grid container xs={8} direction="column">
                    <Grid item>
                      <Typography style={{ marginLeft: '10px', color: 'gray' }}>
                        Scan the QR code affixed
                </Typography>
                      <Typography style={{ marginLeft: '10px', color: 'gray' }}>
                        to the first ticket machine
                </Typography>
                    </Grid>
                  </Grid>
                  <Grid container xs={4} direction="column">
                    <Grid item style={{ textAlign: 'center' }}>
                      <img
                        alt="Logo"
                        src="/images/qr-code.png" />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item style={{ textAlign: 'center' }}>
                  <Button onClick={handleClickOpenValidateCode} style={{ fontSize: "16px", fontWeight: 800, color: 'rgb(25, 118, 210)' }}>
                  Have validation code?
                </Button>
                </Grid> */}

              </div>}




          </DialogContent>


        </Dialog>
        {/* </div> */}
      </Grid> : <div style={{ backgroundColor: 'rgb(232,232,232)!important' }}><Grid container
        direction="row"

        alignItems="center"
        justify="center"

        style={{ paddingTop: '45%', textAlign: 'center' }}>
        <Grid item xs={12} gutterBottom>
          <CloudOffIcon style={{ fontSize: 40, color: "rgb(0,109,53)" }} />
        </Grid>
        <Grid item xs={12} style={{ color: 'black' }}>
          <Typography style={{ fontSize: '20px', fontWeight: 600, marginTop: '10px' }}>Whoops</Typography>
        </Grid>
        <Grid item xs={12} style={{ color: 'rgb(192,192,192)' }}>
          <Typography style={{ fontSize: '15px', marginTop: '7px' }}>Slow or no internet connection.</Typography>
        </Grid>
        <Grid item xs={12} style={{ color: 'rgb(192,192,192)' }}>
          <Typography style={{ fontSize: '15px', }}>Please check your internet settings.</Typography>
        </Grid>
        <Grid style={{ marginTop: '20px' }}>
          <ReplayIcon onClick={handlereload} style={{ fontSize: 45, color: "rgb(0,109,53)" }} />
        </Grid>
      </Grid></div>
      }
    </div >

  );
}

Dashboard.propTypes = {

  history: PropTypes.object
};
export default Dashboard;
