import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import HelpSharpIcon from '@material-ui/icons/HelpSharp';
import moment from 'moment';
import httpUtils from '../../../../common/httpUtils';
import {
  Button,
  Grid,

  Typography,

} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  toggle: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  papergreencard: {


    // maxWidth: 400,
    // backgroundColor: "#f3f3f3",
    backgroundColor: "#F2F4F8"

  },
  imageouterdiv: {
    position: "relative!important",
    // borderRadius: 30
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "210px",
    backgroundColor: 'rgb(0,109,53)'

    // overflow:"hidden"
  },
  image1: {
    objectFit: "cover",
    width: "100%",
    height: "196px",
    backgroundColor: 'rgb(0,109,53)'

    // overflow:"hidden"
  },
  textblock: {
    position: "absolute!important",
    top: "15px",
    width: "100%",
    // left: "10px",
    //   background-color: black,
    color: "white",
  },
  number: {
    // #cccccc
    color: "#9ABBA8", ['@media (max-width:370px)']: { fontSize: "13px", color: "#9ABBA8", lineHeight: "16px" }
  },
  phone: {
    color: "white", fontWeight: 200, paddingBottom: "10px", ['@media (max-width:370px)']: { fontSize: "18px", color: "white", fontWeight: 300, lineHeight: "17px" }
  },
  addcard: {
    fontSize: "25px", marginLeft: '22px', color: 'white', fontWeight: 100,
    ['@media (max-width:370px)']: {
      fontSize: "20px", marginLeft: '22px', color: 'white', fontWeight: 100,
    }, ['@media (min-width:400px)']: {
      fontSize: "25px", marginLeft: '22px', color: 'white', fontWeight: 100,
    }
  },
  addcardthankyou: {
    fontSize: "32px", marginLeft: '25px', color: 'white', fontWeight: 100,
    ['@media (max-width:370px)']: {
      fontSize: "30px", marginLeft: '25px', color: 'white', fontWeight: 100,
    }, ['@media (min-width:400px)']: {
      fontSize: "32px", marginLeft: '25px', color: 'white', fontWeight: 100,
    }
  },
  addcardcheck: {
    fontSize: "15px", marginLeft: '34px', color: 'white', fontWeight: 100,
    ['@media (max-width:370px)']: {
      fontSize: "13px", marginLeft: '34px', color: 'white', fontWeight: 100,
    }, ['@media (min-width:400px)']: {
      fontSize: "15px!important", marginLeft: '34px', color: 'white', fontWeight: 100,
    }
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const [exit, setexit] = React.useState(true)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [bookingType, setbookingType] = React.useState('');
  var bid = (localStorage.getItem('bid'))


  // var CheckinTime=(sessionStorage.checkinTime)
  // console.log(CheckinTime)
  // var CheckinDate=(sessionStorage.checkinDate)
  // console.log(CheckinDate)
  const [checkin, setcheckintime] = React.useState('')
  const [checkindate, setcheckindate] = React.useState('')
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // var garagename=(sessionStorage.garage_name)
  const [garagename, setgaragename] = React.useState('');
  React.useEffect(() => {
    // let url = "https://pialleyapi-dev.divrt.co/api/v1/userConfigByBID/" + bid
    // fetch(url).then((response) => {
    //   response.json().
    httpUtils({
      method: "GET",
      url: "/v1/userConfigByBID/" + bid,

    }).then((response) => {

      // console.log(window.location.href)
      if (response.status) {
        setcheckintime(response['data']['state']['checkinTime'])
        setcheckindate(moment(response['data']['state']['checkinDate']).format('MMMM Do'))
        setgaragename(response['data']['state']['zone']['short_name'])
        setbookingType(response['data']['state']['booking_type'])
        var loc = window.location.href
        var locationsplit = loc.split('/')
        if (locationsplit[(locationsplit.length) - 1] == 'thankyou') {
          setexit(false);
        }
      }
      else {
        console.log(response.message)
      }
    })

    // console.log(locationsplit) 
  }, [])


  return (
    <div className={classes.papergreencard} >
      <Grid container spacing={0} >
        <Grid item xs={12} >

          <div className={classes.imageouterdiv}>
            {exit ? <div className={classes.image} /> : <div className={classes.image1} />}
            <Grid item xs={12}>
              <div className={classes.textblock}>

                {bookingType == '3' ?
                  //hotel reservation link
                  <Grid container>
                    <Grid item xs={9}>

                      <IconButton
                        color="inherit"
                        onClick={onSidebarOpen}
                        className={classes.toggle}
                      >
                        {/* <MenuIcon /> */}
                      </IconButton>


                    </Grid>

                    <Grid item xs={3} style={{ textAlign: "right", marginTop: "32px" }}>
                      <Button component="a" target='_blank' style={{ color: 'white' }}
                        href="https://pialleygarage.com/faq-reservation/">
                        <HelpSharpIcon style={{ fontSize: 35 }} />
                      </Button>
                      {/* <HelpSharpIcon style={{ fontSize: 35 }} onClick={handleClickOpen} /> */}


                    </Grid>
                  </Grid> :
                  <Grid container>
                    <Grid item xs={9}>

                      <IconButton
                        color="inherit"
                        onClick={onSidebarOpen}
                        className={classes.toggle}
                      >
                        {/* <MenuIcon /> */}
                      </IconButton>


                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right", marginTop: "32px" }}>
                      {/* <Button component="a" target='_blank' style={{ color: 'white' }}
                     href="https://pialleygarage.com/faq-reservation/">
                     <HelpSharpIcon style={{ fontSize: 35 }} />
                   </Button> */}
                    
                    </Grid>
                  </Grid>
                }
                  {bookingType == '3' ? 
                  <Grid container>
                  <Grid item xs={9}>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right", marginTop: "5px", padding: "0px 15px" }}>
                    <a href='https://pialleygarage.com/contact-us/' target='_blank'> <img
                      alt="watch"
                      src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px', marginLeft: '4px' }} /></a>
                  </Grid> 
                    </Grid> :
                  <Grid container>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right", marginTop: "43px", padding: "0px 15px" }}>
                      <a href='https://pialleygarage.com/contact-us/' target='_blank'> <img
                        alt="watch"
                        src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px', marginLeft: '4px' }} /></a>
                    </Grid>
                  </Grid>
                }
                {/* <Grid item xs={10}>
                    </Grid>
                  <Grid item xs={2} style={{ marginTop: "5px" }}>
                  <a target='_blank' href="https://pialleygarage.com/faq-reservation/">   <img
                        alt="watch"
                        src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px',marginLeft:'4px' }} /></a>
              </Grid> */}

                {exit ? <Grid container>
                  <Grid item xs={10} style={{ marginTop: "10px" }}>

                    <Typography className={classes.addcard} gutterBottom> You've parked at </Typography>
                  </Grid>
                  {/* <Grid item xs={2} style={{ marginTop: "5px" }}>
                      <img
                        alt="watch"
                        src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px',marginLeft:'4px' }} />
                    </Grid> */}
                  <Grid item s={12}>
                    <Typography className={classes.addcard} > {garagename}</Typography>
                  </Grid>

                </Grid> :

                  <Grid container>
                    <Grid item xs={12} style={{ marginTop: "100px" }}>

                      <Typography className={classes.addcardthankyou} gutterBottom> Thank you! </Typography>
                    </Grid>
                  </Grid>
                }

                {/* {exit?  
              <div>
              <hr style={{ height: '1px', marginTop: "37px", color: 'white' }} />
                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "21px" }}>

                    <Typography className={classes.addcardcheck} gutterBottom> Checked in at {checkin} on {checkindate}</Typography>
                  </Grid>
                </Grid>
                </div>
                :null} */}
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" >
          <CloseIcon onClick={handleClose} style={{ float: 'right', cursor: 'pointer' }} />

        </DialogTitle>
        <div style={{ minHeight: '400px', maxWidth: "270px" }}>
          <DialogContent >
            <Typography gutterBottom style={{ marginTop: "50px", fontSize: "20px", marginLeft: '10px', fontWeight: 800, color: 'rgb(0,109,53)' }}>
              Step 1:
            </Typography>
            <Typography style={{ fontSize: "17px", fontWeight: 500, marginLeft: '10px', color: 'rgb(0,109,53)' }}>
              Drive to the exit gate.
            </Typography>
            <Typography gutterBottom style={{ marginTop: "50px", fontSize: "20px", marginLeft: '10px', fontWeight: 800, color: 'rgb(0,109,53)', marginTop: "30px" }} >
              Step 2:
            </Typography>
            <Typography style={{ fontSize: "17px", fontWeight: 500, marginLeft: '10px', color: 'rgb(0,109,53)' }}>
              Look for the QR code on the exit machine

            </Typography>
            <Typography gutterBottom style={{ marginTop: "50px", fontSize: "20px", marginLeft: '10px', fontWeight: 800, color: 'rgb(0,109,53)', marginTop: "30px" }} >
              Step 3:
            </Typography>
            <Typography gutterBottom style={{ fontSize: "17px", fontWeight: 500, marginLeft: '10px', color: 'rgb(0,109,53)', marginBottom: "100px" }}>
              Tap on 'Scan to Exit' and scan the QR code to lift the gate
            </Typography>
          </DialogContent>
        </div>

      </Dialog>



    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,

};

export default Topbar;
