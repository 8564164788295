import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import httpUtils from '../../common/httpUtils'
import moment from 'moment';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import LanguageIcon from '@material-ui/icons/Language';
import {
  Typography,
  Button
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 'auto',
    backgroundColor: "white"
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'top',

  },
  createdAt: {
    marginTop: '5px',
    color: 'gray',
    fontSize: "14px",



  }, date: {

    marginLeft: '39px',
    lineHeight: '0px',
    marginBottom: "10px",
    fontSize: '13px',
    color: "#A9A9A9",
    paddingBottom: theme.spacing(2)

  },
  image: {
    height: "100px", marginLeft: "-30px"
  },
  text: {
    color: 'black',
    fontSize: '17px',
    marginLeft: '10px',
    marginTop: '10px',
    fontFamily:'sans-serif'
  },
  bottom_but: {
    bottom: '20px',
    // position: 'absolute',
    ['@media (max-width:330px) and (min-height:640px)']: {
      marginTop: '15px',
      bottom: '0px!important',
      position: 'unset!important',
    },
    ['@media (min-width:445px) and (min-height:640px)']: {
      bottom: '0px!important',
      position: 'unset!important',
      marginTop: '15px'
    },
    ['@media (max-height:600px)']: {
      position: 'unset!important',
      marginTop: '15px',
      bottom: '0px!important'
    },
    ['@media(min-width:350px) and (max-width:440px)']:{
      position:'absolute'
    }  
  }
}));

const Cancel = props => {
  const classes = useStyles();
  const { history } = props;
  var bid = (localStorage.getItem('bid'))
  const [midText, setmidText] = useState('')
  const [starttime, setstarttime] = useState('')
  const [endtime, setendtime] = useState('')
  const [bookingdate, setbookingdate] = useState('')
  const [amount, setamount] = useState('')
  const [cancel,setcancel]=useState(false)
  const [jsonArr,setjsonArr]=React.useState({})
  React.useEffect(() => {
    httpUtils({

      method: "GET",
      url: "/v1/userConfigByBID/" + bid,
    }).then(response => {
      if (response.status) {
        if(response['data']['state']['state']==='-99'){
          setcancel(true)
          setamount(response['data']['state']['amount_refunded'])
        }
        if(response['data']['state']['state']==='99'){
          setamount(response['data']['state']['amount']) 
        }
        setmidText(response.data['state']['mid_text'])
        setjsonArr(response['data']['jsonArr'][0])
        setstarttime(moment(response['data']['state']['startTime']).format('MMMM Do hh:mm A'))
        setendtime(moment(response['data']['state']['endtime']).format('MMMM Do hh:mm A'))
        setendtime(moment(response['data']['state']['endtime']).format('MMMM Do hh:mm A'))
        setbookingdate(moment(response['data']['state']['booking_date']).format('MMMM Do YYYY'))
       
      }
      else {
        console.log('Error')
        setmidText(response.message)

      }
    }, (error) => {
      console.log(error)
    })
  }, [])

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <div className={classes.paper}>




            <Grid container direction="row" style={{ marginTop: '10px' }} >
              <Grid item xs={12} >
                <Typography gutterBottom variant="body2" className={classes.text}>
                  {midText}
                </Typography>
              </Grid>



            </Grid>


            {
    Object.entries(jsonArr).map(([key, value]) => (
      <Grid container direction="row" spacing={0} style={{ marginTop: "15px",paddingLeft: "18px"  }} key={key}>
          <Grid item xs={5} style={{ textAlign: "left"}}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px' }}>{key}:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black" }}>   {value}</Typography>
                  </Grid>
      </Grid>
    ))} 

            {/* <Grid container direction="row" spacing={0} style={{ marginTop: "15px", paddingLeft: '18px' }}>
         
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px' }}>Entry By:</Typography>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black" }}>   {starttime}</Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginTop: '10px' }}>Exit By:</Typography>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", marginTop: '10px' }}>{endtime}</Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginTop: '10px' }}>Reservation Date:</Typography>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", marginTop: '10px' }}>{bookingdate}</Typography>
              </Grid>
              {cancel?<Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginTop: '10px' }}>Amount Refunded:</Typography>
              </Grid>:
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginTop: '10px' }}>Amount Paid:</Typography>
              </Grid>}
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", marginTop: '10px' }}>${amount}</Typography>
              </Grid>

            </Grid> */}
          </div>
          <Grid container direction="row" className={classes.bottom_but} >
            <Grid item xs={12} style={{ textAlign: "center" }} >
              <hr style={{ height: '0px!important', color: 'rgb(232,232,232)!important', marginBottom: '15px' }} />
              <Button variant="contained" component="a" target="_blank"
                href="https://pialleygarage.com/"
                style={{ border: '1px solid rgba(0,0,0,1)', minWidth: "270px", height: "60px", backgroundColor: "rgb(0,109,53)", color: "white", fontSize: "20px", fontWeight: 100 }} startIcon={<LanguageIcon />}>Buy Daily Parking </Button>
            </Grid>
          </Grid>





        </Grid>
      </Grid>
    </div >

  );
};
Cancel.propTypes = {

  history: PropTypes.object,
};
export default Cancel;
