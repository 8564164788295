import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import PropTypes from 'prop-types';
import httpUtils from '../../common/httpUtils'
import moment from 'moment';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import SmsIcon from '@material-ui/icons/Sms';
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Typography,
  Button
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 'auto',
    backgroundColor: "white"
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'top',

  },
  createdAt: {
    marginTop: '5px',
    color: 'gray',
    fontSize: "14px",



  }, date: {

    marginLeft: '39px',
    lineHeight: '0px',
    marginBottom: "10px",
    fontSize: '13px',
    color: "#A9A9A9",
    paddingBottom: theme.spacing(2)

  },
  image: {
    height: "100px", marginLeft: "-30px"
  },
  text: {
    color: 'black',
    fontSize: '17px',
    marginLeft: '10px',
    marginTop: '10px'
  }, 
    minimizeScreen:{
    ['@media (min-width:380px)']: {
      marginTop:'15px'
    }, 
    ['@media (max-width:379px)  and (max-height:646px)']: {
      marginTop:'0px'
    },
    ['@media (max-width:379px) and (min-height:645px)']: {
      marginTop:'5px'
    }
  },
    bottom_but: {
    bottom: '20px',
    // position: 'absolute',
    ['@media (max-width:330px) and (min-height:640px)']: {
      marginTop: '15px',
      bottom: '0px!important',
      position: 'unset!important',
    },
    ['@media (min-width:445px) and (min-height:640px)and (max-height:670px)']: {
      bottom: '0px!important',
      position: 'unset!important',
      marginTop: '15px'
    },
    ['@media (max-height:600px) and (max-width:330px)']: {
      position: 'unset!important',
      marginTop: '15px',
      bottom: '0px!important'
    },
    // ['@media(min-width:350px) and (max-width:440px)']: {
    //   position: 'absolute'
    // },
    // ['@media(min-width:381px) and (max-width:444px) and (min-height:545px) and (max-height:578px)']: {
    //   position: 'unset!important',
    //   marginTop: '15px',
    //   bottom: '0px!important'
    // },
    ['@media(min-width:320px) and (max-width:600px) and (min-height:550px) and (max-height:960px)']:{
      position: 'absolute',
      bottom:'10px'
    }
  }, backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: 'rgba(0,0,0,0.3)'
  }
}));

const Thankyou = props => {
  const classes = useStyles();
  const { history } = props;
  const starttime_thankyou = (sessionStorage.starttime_thankyou)
  var bid = (localStorage.getItem('bid'))
  const endtime_thankyou = (sessionStorage.endtime_thankyou)
  const final_amt = (sessionStorage.final_amt)
  const [amount, setamount] = useState('');
  const [starttime, setstarttime] = useState('');
  const [endtime, setendtime] = useState('');
  const [currency, setcurrency] = useState('');
  const [open, setOpen] = React.useState(false);
  const [email, setemail] = React.useState("");
  const [openEmail, setOpenEmail] = React.useState(false)
  const [openErrorEmail, setOpenErrorEmail] = React.useState(false)
  const [opencircular, setopencircular] = React.useState(false);
  const [message, setmessage] = React.useState('');
  const [checkintime, setcheckintime] = React.useState('')
  const [checkouttime, setcheckouttime] = React.useState('') 
  const [jsonArr,setjsonArr]=React.useState({})
  const [multipleInOut,setmultipleInOut]=React.useState(false)
  const [emailerror, setemailerror] = React.useState(false)
  const [prepaidAmt,setprepaidAmt]=React.useState('');
  const [AdditionalAmt,setAdditionalAmt]=React.useState('');
  const [OverDueAmtShow,setOverDueAmtShow]=React.useState(false)
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleCloseEmail = () => {
    setOpenErrorEmail(false)
    setOpenEmail(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // setemail('')
    setopencircular(false)
    setemailerror(false)
    setOpen(false);
  };
  const handlesend = () => {
    setopencircular(true)
    if (email != "") {
      // console.log("in")
      var emailresult = re.test(email)
      // console.log(emailresult)
      if (emailresult == true) {

        //   console.log(true)

        // console.log(email);
        setOpen(false)
        httpUtils({
          method: 'post',
          url: '/v1/mailReservationInvoice',
          data: { "email": email, "booking": bid }
        }).then((response) => {
          console.log(response)
          setopencircular(false)
          if (response.status) {
            setmessage(response.message)
            setOpenEmail(true)
            setemailerror(false)
          }
          else {
            setOpenErrorEmail(true)
            setmessage(response.message)
          }
        }, (error) => {
          console.log(error)
        });
      }
      else {
        setemailerror(true)
      }
    }
    else {
      setemailerror(true)
    }
  }
  React.useEffect(() => {
    httpUtils({
      method: "GET",
      url: "/v1/userConfigByBID/" + bid,
    }).then(response => {
      if (response.status) {
        var OverAmt=response['data']['state']['overdue_amount']
        if(OverAmt>0){
          setOverDueAmtShow(true)
        }
        if(response['data']['state']['allowMultipleInOuts']=='0'){
          setmultipleInOut(false)
          console.log('multiple')
        }
        else{
          setmultipleInOut(true)
        }
        // setstarttime(moment(response['data']['state']['startTime']).format('MMM Do hh:mm A'))
        // setendtime(moment(response['data']['state']['endtime']).format('MMM Do hh:mm A'))
        setstarttime(response['data']['state']['entry'])
        setendtime(response['data']['state']['exit'])
        setamount(response['data']['state']['amount'])
        setcurrency(response['data']['state']['zone']['currency'])
        setjsonArr(response['data']['jsonArr'][0])
        setprepaidAmt(response['data']['state']['prePaidamount'])
        setAdditionalAmt(response['data']['state']['overdue_amount'])
        var checkin = (moment(response['data']['state']['checkinDate']).format('MMM Do'))
        var checkout = (moment(response['data']['state']['checkoutDate']).format('MMM Do'))
        setcheckintime(checkin + " " + response['data']['state']['checkinTime'])
        setcheckouttime(checkout + " " + response['data']['state']['checkoutTime'])
        setemail(response['data']['user']['email'])
      }
     
    })
  }, [])
  const handleEmailCheck = (e) => {
    setemail(e.target.value)
    console.log(e.target.value)
    if (re.test(e.target.value)) {
      setemailerror(false)
    }
  }
  const handleMultiple=()=>{
    // httpUtils({
    //   method: "GET",
    //   url: "/v1/userConfigByBID/" + bid+'?reEnter=1',
    // }).then(response => {
    //   if (response.status) {
    //     console.log(response)
    history.push('/dashboard?reEnter=1' )
//   }
// })
  }
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <div className={classes.paper}>

            <Backdrop className={classes.backdrop} open={opencircular}>
              <CircularProgress style={{ color: "rgb(0,109,53)" }} />
            </Backdrop>
            <Grid container xs={12} direction='row'>



              <Grid container direction="row" >
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.text} style={{marginBottom:'4px'}}>
                    Here is the summary of your recent parking at Pi-Alley
                  </Typography>
                </Grid>
        

              </Grid>
            </Grid>
            {
    Object.entries(jsonArr).map(([key, value]) => (
      <Grid container direction="row" spacing={0} className={classes.minimizeScreen} style={{ paddingLeft: "5px"  }} key={key}>
          <Grid item xs={5} style={{ textAlign: "left"}}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px' }}>{key}:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black",marginLeft: "5px"  }}>   {value}</Typography>
                  </Grid>
      </Grid>
    ))} 
            {/* <Grid container direction="row" spacing={0} style={{ marginTop: "15px", paddingLeft: '5px' }}>

              <Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px' }}>Entered At:</Typography>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black" , marginLeft: '5px'}}>   {starttime}</Typography>
              </Grid>
              <Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginTop: '10px' }}>Exited At:</Typography>
              </Grid>
              <Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", marginTop: '10px' , marginLeft: '5px'}}>{endtime}</Typography>
              </Grid>
              {OverDueAmtShow?<Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginTop: '10px' }}>Amount Paid:</Typography>
              </Grid>:null}
              {OverDueAmtShow?<Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", marginTop: '10px' , marginLeft: '5px'}}>{currency}{prepaidAmt}</Typography>
              </Grid>:null}
              {OverDueAmtShow?<Grid item xs={5} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginTop: '10px' }}>Additional Charges:</Typography>
              </Grid>:null}
              {OverDueAmtShow?<Grid item xs={7} style={{ textAlign: "left" }}>
                <Typography gutterBottom style={{ color: "black", marginTop: '10px' , marginLeft: '5px'}}>{currency}{AdditionalAmt}</Typography>
              </Grid>:null}

            </Grid> */}


        
            <Grid item xs={12}>
              <Typography style={{ fontSize: "27px", textAlign: "center", marginTop: "5px", color: 'rgb(0,109,53)' }}>AMOUNT PAID</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "45px", textAlign: "center", marginTop: "15px", color: 'rgb(0,109,53)', fontWeight: 900 }}>{currency}{amount}</Typography>
            </Grid>

          

            <Grid container direction="row" className={classes.bottom_but}>
              
              <Grid item xs={12} style={{ textAlign: "center" }} >
                <hr style={{ height: '0px!important', color: 'rgb(232, 232, 232)!important  ', marginBottom: '15px' ,marginTop:'10px'}} />
                {multipleInOut?<Button onClick={handleMultiple} variant="contained" style={{ backgroundColor: "rgb(0,109,53)", color: "white", minWidth: "270px", height: "55px", fontSize: "20px", fontWeight: 100 }}>Tap to Re-enter</Button>
                :<Button onClick={handleClickOpen} variant="contained" style={{ backgroundColor: "rgb(0,109,53)", color: "white", minWidth: "270px", height: "55px", fontSize: "20px", fontWeight: 100 }} startIcon={<MailIcon style={{ color: "white" }} />}>Email Receipt</Button>}
              </Grid>
            </Grid>


            <Dialog onClose={handleCloseEmail} disableBackdropClick aria-labelledby="customized-dialog-title" open={openEmail} >

              <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: 'rgb(0,109,53)' }}>
                <CheckCircleIcon  style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />

              </DialogTitle>
              <div style={{ width: '270px', minheight: '130px', padding: '10px ' }}>
                <DialogContent>
                  <Grid xs={12} style={{ textAlign: 'center' }}>
                    <Typography gutterBottom style={{ fontSize: '22px', fontWeight: 600, color: 'rgb(0,109,53)' }}>Success</Typography>
                    <Typography gutterBottom>

                      <p style={{ fontSize: '16px', marginTop: '20px', color: 'black' }}>{message}</p>

                      <div style={{ marginTop: '15px' }}><Button style={{ color: 'white', borderRadius: '16px', width: '70px', backgroundColor: 'rgb(0,109,53)' }} onClick={handleCloseEmail}>OK</Button></div>
                    </Typography>
                  </Grid>
                </DialogContent>
              </div>
            </Dialog>

            <Dialog onClose={handleCloseEmail} disableBackdropClick aria-labelledby="customized-dialog-title" open={openErrorEmail} >

              <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: '#cc0000' }}>
                <ErrorOutlineIcon  style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />

              </DialogTitle>
              <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>
                <DialogContent>
                  <Grid xs={12} style={{ textAlign: 'center' }}>
                    <Typography gutterBottom style={{ fontSize: '22px', fontWeight: 600, color: '#cc0000' }}>Oh Snap!</Typography>
                    <Typography gutterBottom>

                      <p style={{ fontSize: '16px', marginTop: '20px', color: '#cc0000' }}>{message}</p>

                      <div style={{ marginTop: '15px' }}><Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: '#cc0000' }} onClick={handleCloseEmail}> Close</Button></div>
                    </Typography>
                  </Grid>
                </DialogContent>
              </div>
            </Dialog>

            <Dialog
              open={open}
              disableBackdropClick
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
            >

              <DialogTitle id="form-dialog-title">Send Email</DialogTitle>
              <div style={{ minHeight: '150px', width: "290px", padding: '0px 18px' }}>
                <DialogContent>
                  <DialogContentText style={{ color: '#000' }}>Send the receipt to my email ID</DialogContentText>
                  {emailerror ? <TextField
                    error
                    id="standard-error-helper-text"
                    autoFocus
                    margin="dense"
                    id="name"
                    autoComplete='off'
                    value={email}
                    label=""
                    helperText="Invalid Email ID or empty field"
                    type="email"
                    onChange={handleEmailCheck}
                    fullWidth
                  /> : <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      autoComplete='off'
                      value={email}
                      label="Email Address"
                      type="email"
                      onChange={(e) => setemail(e.target.value)}
                      fullWidth
                    />}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="gray">
                    Cancel
          </Button>
                  <Button onClick={handlesend} color="primary">
                    SEND
          </Button>
                </DialogActions>
              </div>
            </Dialog>

          </div>




        </Grid>
      </Grid>
    </div >

  );
};
Thankyou.propTypes = {

  history: PropTypes.object,
};
export default Thankyou;
