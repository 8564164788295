import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import httpUtils from '../../../../common/httpUtils';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
// import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';

import HelpSharpIcon from '@material-ui/icons/HelpSharp';
import {
  Button,
  Grid,

  Typography,

} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  toggle: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  papergreencard: {


    backgroundColor: "#F2F4F8"

  },
  imageouterdiv: {
    position: "relative!important",

  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "200px"



  },
  textblock: {
    position: "absolute!important",
    top: "15px",
    width: "100%",

    color: "white",
  },
  number: {

    color: "#9ABBA8", ['@media (max-width:370px)']: { fontSize: "13px", color: "#9ABBA8", lineHeight: "16px" }
  },
  phone: {
    color: "white", fontWeight: 200, paddingBottom: "10px", ['@media (max-width:370px)']: { fontSize: "18px", color: "white", fontWeight: 300, lineHeight: "17px" }
  },
  addcard: {
    fontSize: "17px", lineHeight: "45px", color: "black", fontFamily: "sans-serif",
    ['@media (max-width:370px)']: {
      fontSize: "16px", lineHeight: "45px", color: "black", fontFamily: "sans-serif",
    }, ['@media (min-width:400px)']: {
      fontSize: "15px!important", fontFamily: "sans-serif", lineHeight: "50px!important", color: "black",
    }
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const [garagename, setgaragename] = React.useState('');
  const [bookingType, setbookingType] = React.useState('');
  const classes = useStyles();
  const [open, setOpen] = React.useState();
  var bid = (localStorage.getItem('bid'))

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    httpUtils({
      method: "GET",
      url: "v1/userConfigByBID/" + bid,
    }).then((response) => {

      if (response.status) {
        setgaragename(response['data']['state']['zone']['short_name'])
        setbookingType(response['data']['state']['booking_type'])
      }
    }, (error) => {
      console.log(error)
    })
  }, [])

  React.useEffect(() => {
    // console.log(localStorage.getItem('DailogState'))
    var check = localStorage.getItem('DailogState')

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    // console.log(iOS)
    if (iOS) {
      // console.log('inIOS')
      let userAgentString =
        navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      // console.log(chromeAgent)
      // if (chromeAgent) {
      //   setisIOS(true)
      //   console.log('inloop')
      // }
      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
      if (isSafari) {
        // setisIOS(false)
        if (check == 'true') {
          setOpen(true)
          // console.log('click')
          localStorage.setItem('DailogState', false)
          setTimeout(() => {
            setOpen(false)
          }, 5000)
        }
        // console.log('keepasit')
      }
      else {
        // setisIOS(true)

        // console.log('change')
      }
    }
    else {
      var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
      // console.log(isSafari)
      let userAgentString =
        navigator.userAgent;
      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
      // console.log('inelse')
      // console.log(chromeAgent)
      if (check == 'true') {
        setOpen(true)
        // console.log('click')
        localStorage.setItem('DailogState', false)
        setTimeout(() => {
          setOpen(false)
        }, 5000)
      }
    }

  }, [garagename])


  return (


    <div className={classes.papergreencard} >
      <Grid container spacing={0} >
        <Grid item xs={12} >

          <div className={classes.imageouterdiv}> <img className={classes.image}
            alt="Logo"
            src="/images/logos/logo.jpg" />
            <Grid xs={12}>
              <div className={classes.textblock}>


                {bookingType == '3' ?
                  //hotel reservation link
                  <Grid container>
                    <Grid item xs={9}>

                      <IconButton
                        color="inherit"
                        onClick={onSidebarOpen}
                        className={classes.toggle}
                      >
                        {/* <MenuIcon /> */}
                      </IconButton>


                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right", marginTop: "32px" }}>
                      <Button component="a" target='_blank' style={{ color: 'white' }}
                        href="https://pialleygarage.com/faq-reservation/">
                        <HelpSharpIcon style={{ fontSize: 35 }} />
                      </Button>
                      {/* <HelpSharpIcon style={{ fontSize: 35 }} onClick={handleClickOpen} /> */}
                    </Grid>
                  </Grid> :
                  //daily booking FAQ link
                  <Grid container>
                    <Grid item xs={9}>

                      <IconButton
                        color="inherit"
                        onClick={onSidebarOpen}
                        className={classes.toggle}
                      >
                        {/* <MenuIcon /> */}
                      </IconButton>


                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right", marginTop: "32px" }}>
                      {/* <Button component="a" target='_blank' style={{ color: 'white' }}
                        href="https://pialleygarage.com/faq-reservation/">
                        <HelpSharpIcon style={{ fontSize: 35 }} />
                      </Button> */}
                      {/* <HelpSharpIcon style={{ fontSize: 35 }} onClick={handleClickOpen} /> */}
                    </Grid>
                  </Grid>
                }

                {bookingType == '3' ? <Grid container>
                  <Grid item xs={9}>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right", marginTop: "5px", padding: "0px 15px" }}>
                    <a href='https://pialleygarage.com/contact-us/' target='_blank'> <img
                      alt="watch"
                      src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px', marginLeft: '4px' }} /></a>
                  </Grid>
                </Grid> :
                  <Grid container>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right", marginTop: "43px", padding: "0px 15px" }}>
                      <a href='https://pialleygarage.com/contact-us/' target='_blank'> <img
                        alt="watch"
                        src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px', marginLeft: '4px' }} /></a>
                    </Grid>
                  </Grid>
                }
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                  <DialogTitle id="customized-dialog-title" >
                    <CloseIcon onClick={handleClose} style={{ float: 'right', cursor: 'pointer' }} />

                  </DialogTitle>
                  <div style={{ minHeight: '400px', maxWidth: "270px" }}>
                    <DialogContent >
                      <Typography gutterBottom style={{ padding: "5px", textAlign: "center", fontSize: "20px", fontWeight: 800, color: '#CC0000' }}>
                        *Do not take a ticket to vend the entry gate.
                      </Typography>
                      <Typography gutterBottom style={{ marginTop: "30px", marginLeft: '10px', fontSize: "20px", fontWeight: 800, color: 'rgb(0,109,53)' }}>
                        Step 1:
                      </Typography>
                      <Typography style={{ fontSize: "17px", marginLeft: '10px', fontWeight: 500, color: 'rgb(0,109,53)' }}>
                        Drive to the entry gate.
                      </Typography>
                      <Typography gutterBottom style={{ marginTop: "50px", marginLeft: '10px', fontSize: "20px", fontWeight: 800, color: 'rgb(0,109,53)', marginTop: "30px" }} >
                        Step 2:
                      </Typography>
                      <Typography style={{ fontSize: "17px", fontWeight: 500, marginLeft: '10px', color: 'rgb(0,109,53)' }}>
                        Look for the QR code on the first ticket machine
                      </Typography>
                      <Typography gutterBottom style={{ marginTop: "50px", marginLeft: '10px', fontSize: "20px", fontWeight: 800, color: 'rgb(0,109,53)', marginTop: "30px" }} >
                        Step 3:
                      </Typography>
                      <Typography gutterBottom style={{ fontSize: "17px", marginLeft: '10px', fontWeight: 500, color: 'rgb(0,109,53)', marginBottom: "100px" }}>
                        Tap on 'Scan to Enter' and scan the QR code to lift the gate
                      </Typography>
                    </DialogContent>
                  </div>

                </Dialog>

                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "5px" }}>

                    <div className={classes.addcard} style={{ backgroundColor: "rgba(22,22,22,0.7)", width: "auto", marginTop: "12px" }} > <p style={{ color: "white", marginLeft: "25px", fontSize: '21px' }} > {garagename}</p> </div>
                    {/* </div> */}
                  </Grid>
                </Grid>

                {/* </Grid> */}
              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>

    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,

};

export default Topbar;
