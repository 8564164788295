import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import MailIcon from '@material-ui/icons/Mail';
import LanguageIcon from '@material-ui/icons/Language';
import {

  Button,
 
} from '@material-ui/core';
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  content: {
    paddingTop: 20,
    textAlign: 'center'
  },
  image: {
    paddingTop:50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFound = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        justify="center"
        spacing={0}
        direction="row"
      >
        <Grid
          item
          lg={12}
          xs={12}
        >
              <img
              alt="Pialley Garage"
              className={classes.image}
              src="images/ic_pialley_logo_signup.png"
            />
          <div className={classes.content}>
            <Typography variant="h3" >
              Sorry, the Reservation-ID is invalid 
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
           Try clicking on the revservation link again
            </Typography>
            <Typography variant="subtitle2" style={{color:'gray'}}>
              {/* You either tried some shady route or you came here by mistake.
              Whichever it is, try clicking on message link again */}
              Incase of any queries, drop an email or visit the Pi-Alley website to buy daily parking
            </Typography>

            <Grid container direction="row" spacing={0} style={{marginTop:'10px'}}>
                  <Grid item xs={4} style={{ textAlign: "right" }}>
                    <Button variant="outlined" component="a" target='_blank'
                      href='mailto:support@pialleygarage.com' style={{ border: '2px solid rgba(38,50,56,1)' }} startIcon={<MailIcon style={{ backgroundColor: 'gray', color: "white" }} />}>Email</Button>
                  </Grid>
                  <Grid item xs={8} style={{ textAlign: "center" }}>
                    <Button variant="contained" component="a" target='_blank'
                      href="https://pialleygarage.com"
                      style={{ border: '1px solid rgba(0,0,0,1)', backgroundColor: "rgb(0,109,53)", color: "white" }} startIcon={<LanguageIcon />}>Buy Daily Parking </Button>
                  </Grid>
                </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default NotFound;
