import axios from 'axios'
const HttpUtils = function (options) {
    var environment = '';
    environment = window.location.hostname;
    console.log(environment)
    var data = { 'endPoint': '' }
    switch (environment) {

        case 'localhost':
            data = {
                endPoint: 'https://meghak.divrt.co/api'
            };
            break;

        case 'karthikey.divrt.co':
            data = {
                endPoint: 'https://meghak.divrt.co/api'
            };
            break;
        case 'booking-dev.divrt.co':
            data = {
                endPoint: ' https://pialleyapi-dev.divrt.co/api'
            };
            break;
        case 'booking.divrt.co':
            data = {
                endPoint: ' https://pialleyapi.divrt.co/api'
            };
            break;
        default:
            data = {
                endPoint: ' https://pialleyapi-dev.divrt.co/api'

            };

    }
    var client = axios.create({
        baseURL: data.endPoint.trim()
    });
    const onSuccess = function (response) {
        return response.data;
    };
    const onError = function (error) {
        return error;
    }
    return client(options)
        .then(onSuccess)
        .catch(onError);
}
export default HttpUtils;