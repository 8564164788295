import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
// import RoomIcon from '@material-ui/icons/Room';
import PropTypes from 'prop-types';
// import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import httpUtils from '../../common/httpUtils'
import moment from 'moment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import QrReader from 'react-qr-reader'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import useClipboard from 'react-hook-clipboard'
import {

  Button,
  TextField,
  Typography,

} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0)
  },
  paper: {
    padding: theme.spacing(0),
    margin: 'auto',
    maxWidth: 'auto',
    backgroundColor: "white"
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'top ',
    // lineHeight:'px', 
    // marginLeft:"5px"
  },
  createdAt: {

    fontWeight: 600,
    fontSize: "22px",
    marginTop: "32px",

  }, date: {
    marginLeft: '85px',
    lineHeight: '0px',
    // marginBottom: "10px",
    fontSize: '17px',
    marginTop: "-30px"
  },
  image: {
    height: "100px", marginLeft: "-30px"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: 'rgba(0,0,0,0.3)'
  },
  minimizeScreen: {
    ['@media (min-width:380px)']: {
      marginBottom: '15px'
    },
    ['@media (max-width:379px)  and (max-height:646px)']: {
      marginBottom: '0px'
    },
    ['@media (max-width:379px) and (min-height:645px)']: {
      marginBottom: '5px'
    }
  },
  minimizefontSize: {
    ['@media (min-width:380px)']: {
      fontSize: '14px'
    },
    ['@media (max-width:379px)']: {
      fontSize: '13px'
    },
  },
  // bottom_but: {
  //   // marginTop:'10px',
  //   // marginBottom:'10px',
  //   marginTop: '0px',
  //   marginBottom: '5px',
  //   ['@media (max-width:365px)']: {
  //     position:'absolute',
  //     bottom:'1px',
  //   },
  //   ['@media (max-width:330px) and (min-height:640px)']: {
  //     // marginTop: '15px',
  //     bottom: '0px!important',
  //     position: 'unset!important',

  //   },
  //   ['@media (min-width:445px)']: {
  //     bottom: '20px!important',
  //     marginTop: '15px',

  //   },
  //   ['@media (min-width:380px) and (max-width:440px) and (min-height:660px)']: {
  //     bottom: '20px!important',
  //     position: 'absolute',
  //     marginTop: '10px',


  //   },
  //   ['@media (min-width:370px) and (max-width:380px) and (min-height:660px)']: {
  //     bottom: '10px!important',
  //     position: 'absolute',

  //     marginTop: '10px',
  //   }, 
  //    ['@media(min-width:381px) and (max-width:444px) and (min-height:545px) and (max-height:578px)']: {
  //     position: 'unset!important',
  //     marginTop: '15px',
  //     bottom: '0px!important'
  //   },
  //   ['@media(min-width:310px) and (max-width:580px) and (min-height:600px) and (max-height:900px)']:{
  //     position: 'absolute',
  //     bottom:'3px'
  //   }
  // }
  bottom_but: {
    bottom: '20px',
    // position: 'absolute',
    ['@media (max-width:330px) and (min-height:640px)']: {
      marginTop: '15px',
      bottom: '0px!important',
      position: 'unset!important',
    },
    ['@media (min-width:445px) and (min-height:640px) and (max-height:670px)']: {
      bottom: '0px!important',
      position: 'unset!important',
      marginTop: '15px'
    },
    ['@media (max-height:600px) and (max-width:330px)']: {
      position: 'unset!important',
      marginTop: '15px',
      bottom: '0px!important'
    },
    // ['@media(min-width:350px) and (max-width:440px)']: {
    //   position: 'absolute'
    // },
    // ['@media(min-width:381px) and (max-width:444px) and (min-height:545px) and (max-height:578px)']: {
    //   position: 'unset!important',
    //   marginTop: '15px',
    //   bottom: '0px!important'
    // },
    ['@media(min-width:320px) and (max-width:600px) and (min-height:520px) and (max-height:960px)']: {
      position: 'absolute!important',
      bottom: '10px!important'
    },
  },
  bottom_but_unset_small_phn: {
    bottom: '20px',
    // position: 'absolute',
    ['@media (max-width:330px) and (min-height:640px)']: {
      marginTop: '15px',
      bottom: '0px!important',
      position: 'unset!important',
    },
    ['@media (min-width:445px) and (min-height:640px) and (max-height:670px)']: {
      bottom: '0px!important',
      position: 'unset!important',
      marginTop: '15px'
    },
    ['@media (max-height:600px) and (max-width:330px)']: {
      position: 'unset!important',
      marginTop: '15px',
      bottom: '0px!important'
    },
    // ['@media(min-width:350px) and (max-width:440px)']: {
    //   position: 'absolute'
    // },
    // ['@media(min-width:381px) and (max-width:444px) and (min-height:545px) and (max-height:578px)']: {
    //   position: 'unset!important',
    //   marginTop: '15px',
    //   bottom: '0px!important'
    // },
    ['@media(min-width:360px) and (max-width:600px) and (min-height:585px) and (max-height:960px)']: {
      position: 'absolute',
      bottom: '10px'
    },
    ['@media(min-width:320px) and (max-width:375px) and (min-height:520px) and (max-height:600px)']: {
      position: 'unset!important',
      bottom: '10px'
    }
  },
  suc_btn:{
    backgroundColor: "rgb(0,109,53)", color: "white"
  },
  def_btn:{
    color:'gray!important'
  }
}));

const ProductList = props => {
  const classes = useStyles();
  const { history } = props;

  const [overdue_amt, setoverdue_amt] = React.useState(0)
  var bid = (localStorage.getItem('bid'))
  const [activeStep, setActiveStep] = React.useState(0);

  var input = '';
  const theme = useTheme();
  const [opencircular, setopencircular] = React.useState(false);
  const estimate = (sessionStorage.est_amt)
  const [timer, settimer] = useState(0)
  const [amount, setamount] = useState('');
  const [starttime, setstarttime] = useState('');
  const [endtime, setendtime] = useState('');
  const [currency, setcurrency] = useState('');
  const [open, setOpen] = React.useState(false);
  const [showtimer, setshowtimer] = React.useState(false)
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEstimate, setOpenEstimate] = useState(false);
  const [countertime, setcountertime] = useState('');
  const [text, settext] = useState('');
  const [SnackErrMessage, setSnackErrMessage] = useState('');
  const [overtime, setovertime] = useState('');
  const [additionalData, setadditionalData] = useState(false)
  const [GracetmeforCheckout, setGracetmeforCheckout] = useState('');
  const [nocharges, setnocharges] = useState(true)
  const [additionalstarttime, setadditionalstarttime] = useState('');
  const [additionalendtime, setadditionalendtime] = useState('');
  const [additionaltime, setadditionaltime] = useState('');
  const [showOverAmtPop, setshowOverAmtPop] = useState(false)
  const [openWarning, setOpenWarning] = React.useState(false);
  const [isIOS, setisIOS] = useState(false);
  const [jsonArr, setjsonArr] = React.useState({})
  const [showAddiText, setshowAddiText] = React.useState(false)
  const [openExtend, setopenExtend] = React.useState(false)
  const [callLogs, setcallLogs] = useState(false)
  const [showCameraPermissionError, setshowCameraPermissionError] = useState(false)
  const [showIOSCameraLink, setshowIOSCameraLink] = useState(false)
  // const [clipboard, copyToClipboard] = useClipboard()
  const copyurl = (window.location.origin)
  // console.log(copyurl)
  const toClipboard = copyurl + '?PIA=' + bid + '&'
  const [validateCode, setValidateCode] = useState("");
  const [openValidateCode, setOpenValidateCode] = useState(false)
  // console.log(toClipboard)
  // const toClipboard = 'http://booking-dev.divrt.co/?PIA=' + bid + '&'
  // const[offsets,setoffsets]=useState('')
  // const [endTimestamp,setendTimestamp]=useState('')
  const [openCopied, setOpenCopied] = React.useState(false);
  var callAPI = 0;
  const [errormessage, seterrormessage] = useState('')
  var splitendtime;
  var Store_offsets;
  var grace_time;

  const handleCloseWarning = () => {
    setOpenWarning(false);
  }

  const handleCloseCopied = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenCopied(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleCopy = (value) => {
    // console.log('click')
    // copyToClipboard(toClipboard)
    navigator.clipboard.writeText(toClipboard, null, '\t')
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setOpenCopied(true)
  }
  const handleOpenbrowser = () => {
    // console.log('open')
    window.location.href = "x-web-search://";
    setOpenWarning(false)
  }
  const tutorialSteps = [
    {
      no: "Step 1:",
      buttonText: "Copy",
      func: handleCopy,
      label: "Tap on the button to copy the reservation link",
    },
    {
      no: "Step 2:",
      buttonText: "Open Browser",
      func: handleOpenbrowser,
      label: "Tap on the button below to open Safari and paste the copied link",

    }
  ]
  const maxSteps = tutorialSteps.length;

  const handleClickOpenEstimate = () => {

    httpUtils({
      method: "GET",
      url: "/v1/getRateByBID/" + bid,
    }).then(response => {
      setopencircular(false)
      if (response.status) {
        // console.log('in')
        if (response['state'] == '8' || response['state'] == '10') {
          history.push('/thankyou')
          // console.log('forecedcheckout')
        }
        else {
          if (response['dues']['over_due_amount'] > 0) {
            setnocharges(false)
            setOpenEstimate(true)
            setoverdue_amt(response['dues']['over_due_amount'])
            // setadditionalData(true)
            // console.log('data')
            setadditionalstarttime(response['time_details']['checkinDatetime']);
            setadditionalendtime(response['time_details']['checkoutDatetime']);
            var t = Math.round((response['time_details']['over_due_time']) / 60)
            console.log(t)
            if (t < 60) {
              setadditionaltime((t) + " mins")
            }
            else if (t >= 60 && t <= 1440) {
              setadditionaltime(parseInt(t / 60) + " Hr" + " " + (t % 60) + " Mins")
            }
            else if (t > 1440) {
              // console.log(t)
              var d = parseInt(t / 1440)
              if (parseInt(t / 1440) == '1') {
                setadditionaltime(parseInt(t / 1440) + " Day" + " " + (parseInt((t - (d * 1440)) / 60)) + " Hr" + " " + parseInt(t % 60) + " Mins")
              }
              else {


                setadditionaltime(parseInt(t / 1440) + " Days" + " " + (parseInt((t - (d * 1440)) / 60)) + " Hr" + " " + parseInt(t % 60) + " Mins")
              }
            }
          }
          if (response['dues']['over_due_amount'] <= 0) {
            setnocharges(true)
            setOpenEstimate(true)
          }

        }

      }
      else {
        // console.log('error')
        // console.log(response.message)
        setSnackErrMessage(response.message)
        setOpenSnack(true)
      }
    })

  };
  const handleClickOpenExit = () => {

    httpUtils({
      method: "GET",
      url: "/v1/getRateByBID/" + bid,
    }).then(response => {
      setopencircular(false)
      if (response.status) {
        // console.log('in')
        if (response['state'] == '8' || response['state'] == '10') {
          history.push('/thankyou')
          // console.log('forecedcheckout')
        }
        else {
          if (response['dues']['over_due_amount'] > 0) {

            setoverdue_amt(response['dues']['over_due_amount'])
            setadditionalData(true)
            // console.log('data')
            setadditionalstarttime(response['time_details']['checkinDatetime']);
            setadditionalendtime(response['time_details']['checkoutDatetime']);
            var t = Math.round((response['time_details']['over_due_time']) / 60)
            if (t < 60) {
              setadditionaltime((t) + " mins")
            }
            else if (t >= 60 && t <= 1440) {
              setadditionaltime(parseInt(t / 60) + " Hr" + " " + (t % 60) + " Mins")
            }
            else if (t > 1440) {
              // console.log(t)
              var d = parseInt(t / 1440)
              if (parseInt(t / 1440) == '1') {
                setadditionaltime(parseInt(t / 1440) + " Day" + " " + (parseInt((t - (d * 1440)) / 60)) + " Hr" + " " + parseInt(t % 60) + " Mins")
              }
              else {
                setadditionaltime(parseInt(t / 1440) + " Days" + " " + (parseInt((t - (d * 1440)) / 60)) + " Hr" + " " + parseInt(t % 60) + " Mins")
              }
            }
          }


        }
        setshowCameraPermissionError(false)
        setcallLogs(true)
        setTimeout(() => {
          console.log('close')
          httpUtils({
            method: "POST",
            url: "/webview/logs",
            data: { "bid": bid, "message": "Qr code not scanned from last 30 sec in product route." }
          }).then(response => {
          }).catch(err => {
            console.log(err)
          })
          setOpenDialog(false);

        }, 32000)
        setOpenDialog(true);
      }
      else {
        // console.log('error')
        // console.log(response.message)
        setSnackErrMessage(response.message)
        setOpenSnack(true)
      }
    })

  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleExitScan = () => {
    if (isIOS) {
      setOpenWarning(true)
    }
    else {

      setopencircular(true)
      handleClickOpenExit()
    }
  }
  const handleEstimate = () => {
    setopencircular(true)
    handleClickOpenEstimate()

  }
  const handleExtendReserv = () => {

    setopenExtend(true)
  }
  const handleExtendClose = () => {
    setopenExtend(false)
  }
  const handleEstimateClose = () => {
    setOpenEstimate(false)

  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  React.useLayoutEffect(() => {

    if (!bid) {
      // Call logs API

      httpUtils({
        method: "POST",
        url: "/webview/logs",
        data: { "bid": "null", "message": "ID not present in the URL in product page in first API call" }
      }).then(response => {
        // console.log(response)

      })
      history.push('/not-found')
    }
    httpUtils({

      method: "GET",
      url: "/v1/userConfigByBID/" + bid,
    }).then(response => {

      if (response.status) {


        if (response['data']['state']['state'] == '8' || response['data']['state']['state'] == '10') {
          history.push('/thankyou')
        }
        else if (response['data']['state']['state'] == '2') {
          history.push('/estimate')
        }
        if (response['data']['state']['state'] == '-1') {
          history.push('/not-found')
        }
        if (response['data']['state']['state'] == '-99') {
          history.push('/cancelled')
        }
        if (response['data']['state']['state'] == '99') {
          history.push('/cancelled')
        }
        // var d = new Date(response['data']['state']['endtime']).getTime().toString();
        if (response['data']['state']['booking_type'] == '3') {
          console.log('change')
          setshowAddiText(true)
        }
        splitendtime = response['data']['state']['endTimestamp']
        // setendTimestamp(response['data']['state']['endTimestamp'])
        // var splitendtime = response['data']['state']['expectedCheckoutTime']
        // console.log(splitendtime)
        var current = Math.floor((new Date()).getTime() / 1000)

        var offset1 = parseInt(response['data']['state']['zone']['timezonedstoffset'])

        var offset2 = parseInt(response['data']['state']['zone']['timezoneoffset'])
        Store_offsets = offset1 + offset2
        var current_offsets = parseInt(current) + offset1 + offset2
        console.log(current_offsets)
        // setoffsets(current_offsets)

        var minutes = Math.round((splitendtime - current_offsets) / 60)
        var min_sec = ((splitendtime - current_offsets) / 60)
        // console.log(min_sec)

        // console.log(minutes)
        grace_time = (response['data']['state']['zone']['graceTimeForCheckout'])
        console.log(grace_time)

        var grace_starttime = (response['data']['state']['start12PMTimestamp']) - ((response['data']['state']['zone']['hotelgraceTimeToCheckin']) * 60)

        if (response['data']['state']['booking_type'] == '3') {
          if ((response['data']['state']['checkinTimestamp']) < grace_starttime && (response['data']['state']['checkinTimestamp'] < response['data']['state']['start12PMTimestamp'])) {
            setshowOverAmtPop(true)
            console.log('checked')
          }
        }

        if (minutes < 0) {
          settext('parking time exceeded')

          var pos = (-minutes)
          // console.log(pos)
          if (pos >= grace_time) {
            setshowOverAmtPop(true)
            // console.log('in')
          }
          setovertime(true)
          settimer(pos)
          var managetime = pos
        }
        if (minutes >= 0) {
          settext('remaining')

          setovertime(false)
          settimer(minutes)
          var managetime = minutes
        }

        if (response['data']['state']['booking_type'] == '3') {
          console.log('hotel')
          if (minutes < 0) {
            setshowtimer(true)
          }
          else {
            setshowtimer(false)
          }
        }
        else {
          console.log('daily')
          setshowtimer(true)


        }



        if (managetime < 60) {
          setcountertime((managetime) + " mins")
        }
        else if (managetime >= 60 && managetime <= 1440) {
          setcountertime(parseInt(managetime / 60) + " Hr" + " " + (managetime % 60) + " Mins")
        }
        else if (managetime > 1440) {
          var d = parseInt(managetime / 1440)
          if (parseInt(managetime / 1440) == '1') {
            setcountertime(parseInt(managetime / 1440) + " Day" + " " + (parseInt((managetime - (d * 1440)) / 60)) + " Hr" + " " + parseInt(managetime % 60) + " Mins")
          }
          else {
            setcountertime(parseInt(managetime / 1440) + " Days" + " " + (parseInt((managetime - (d * 1440)) / 60)) + " Hr" + " " + parseInt(managetime % 60) + " Mins")
          }
        }
        // if(managetime>=60 && managetime<=1440){
        //   var hr
        // }
        // setstarttime(moment(response['data']['state']['startTime']).format('MMMM Do hh:mm A'))
        // setendtime(moment(response['data']['state']['endtime']).format('MMMM Do hh:mm A'))
        setstarttime(response['data']['state']['entry'])
        setjsonArr(response['data']['jsonArr'][0])
        setGracetmeforCheckout(response['data']['state']['zone']['graceTimeForCheckout'])
        var grace = response['data']['state']['zone']['graceTimeForCheckout']
        localStorage.setItem('grace_time', grace)
        setendtime(response['data']['state']['exit'])
        setamount(response['data']['state']['prePaidamount'])
        setcurrency(response['data']['state']['zone']['currency'])
        var checkin = response['data']['state']['checkinTime']
        // console.log(checkin)
        sessionStorage.setItem('checkinTime', checkin)
        var checkindate = moment(response['data']['state']['checkinDate']).format('MMMM Do')

        sessionStorage.setItem('checkinDate', checkindate)

        var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        // console.log(iOS)
        if (iOS) {
          // console.log('inIOS')
          let userAgentString =
            navigator.userAgent;
          let chromeAgent = userAgentString.indexOf("Chrome") > -1;
          // console.log(chromeAgent)
          var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
          if (isSafari) {
            setisIOS(false)
            setshowIOSCameraLink(true)
            // console.log('keepasit')
          }
          else {
            setisIOS(true)
            // console.log('change')
          }
        }
        else {
          var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
          // console.log(isSafari)
          var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
          if (isSafari && iOS) {
            console.log("You are using Safari on iOS!");
          }
        }
      }

    })
  }, [])

  React.useEffect(() => {
    window.addEventListener("focus", onFocus)
    // console.log('evenlistener')
    return () => {
      window.removeEventListener('focus', onFocus);
    };

  }, [])
  const onFocus = () => {
    // console.log('active')
    var current = Math.floor((new Date()).getTime() / 1000)
    var current_offsets = parseInt(current) + Store_offsets
    var minutes = Math.round((splitendtime - current_offsets) / 60)
    console.log(minutes)
    if (minutes < 0) {
      setshowtimer(true)
      settext('parking time exceeded')
      // console.log(grace_time)
      var pos = (-minutes)
      if (pos >= grace_time) {
        // console.log('infocus')
        setshowOverAmtPop(true)
      }
      setovertime(true)
      settimer(pos)
      var managetime = pos
    }
    if (minutes >= 0) {
      settext('remaining')

      setovertime(false)
      settimer(minutes)
      var managetime = minutes
    }

    if (managetime < 60) {
      setcountertime((managetime) + " mins")
    }
    else if (managetime >= 60 && managetime <= 1440) {
      setcountertime(parseInt(managetime / 60) + " Hr" + " " + (managetime % 60) + " Mins")
    }
    else if (managetime > 1440) {
      var d = parseInt(managetime / 1440)
      if (parseInt(managetime / 1440) == '1') {
        setcountertime(parseInt(managetime / 1440) + " Day" + " " + (parseInt((managetime - (d * 1440)) / 60)) + " Hr" + " " + parseInt(managetime % 60) + " Mins")
      }
      else {
        setcountertime(parseInt(managetime / 1440) + " Days" + " " + (parseInt((managetime - (d * 1440)) / 60)) + " Hr" + " " + parseInt(managetime % 60) + " Mins")
      }
    }
    window.addEventListener("focus", onFocus)

  }
  React.useLayoutEffect(() => {

    var grace_time = (localStorage.getItem('grace_time'))
    // console.log(grace_time)
    const s = setInterval(() => {
      var t = 0;

      if (timer == 0) {
        setshowtimer(true)
        setovertime(true)
        t = timer + 1
        settimer(c => c + 1)
        settext('parking time exceeded')
      }
      else {
        if (overtime) {
          setshowtimer(true)
          // console.log(timer)
          // console.log(grace_time)
          if (timer + 1 >= grace_time) {
            // console.log('ininterval')
            setshowOverAmtPop(true)
          }
          t = timer + 1
          settimer(c => c + 1)
          settext('parking time exceeded')
        }
        else {

          t = timer - 1
          settimer(c => c - 1)
          settext("remaining")
        }
      }
      if (t < 60) {
        console.log(t)
        setcountertime(t + " mins")
      }
      else if (t >= 60 && t <= 1440) {
        setcountertime(parseInt((t) / 60) + " Hr" + " " + ((t) % 60) + " Mins")
      }
      else if (t > 1440) {
        var d = parseInt(t / 1440)
        if (parseInt(t / 1440) == '1') {
          setcountertime(parseInt(t / 1440) + " Day" + " " + (parseInt((t - (d * 1440)) / 60)) + " Hr" + " " + parseInt(t % 60) + " Mins")
        }
        else {
          setcountertime(parseInt(t / 1440) + " Days" + " " + (parseInt((t - (d * 1440)) / 60)) + " Hr" + " " + parseInt(t % 60) + " Mins")
        }
      }
    }, 60000)
    return () => clearInterval(s)

  }, [timer])

  const handleValidateCode = (e) => {
    // console.log(e.target.value)
    console.log(validateCode)
    setValidateCode(e.target.value)
  }

  const handleSendValidateCode = () => {
    console.log(validateCode)
    setOpenValidateCode(false);
    setopencircular(true)

    const send = { "bid": bid, "beaconId": validateCode,"type":'CODE' }
    httpUtils({
      method: "POST",
      url: "/v1/webCheckOut",
      data: send
    }).then(response => {

      setopencircular(false)
      if (response.status) {

        history.push('/thankyou')
      }

      else {
        seterrormessage(response.message)
        setOpen(true);

      }
    }, (error) => {
      console.log(error);
    });
  }

  const handleScan = data => {
    //call logs API
    //  console.log(data,new Date().toLocaleTimeString())
    if (!bid) {
      // Call logs API

      httpUtils({
        method: "POST",
        url: "/webview/logs",
        data: { "bid": "null", "message": "ID not present in the URL in product page in handleScan" }
      }).then(response => {
        // console.log(response)

      })
      history.push('/not-found')
    }
    if (callLogs) {
      httpUtils({
        method: "POST",
        url: "/webview/logs",
        data: { "bid": bid, "message": "Success: Pop-up opened from Checkout(product) route with camera permission" }
      }).then(response => {
        // console.log(response)
        setcallLogs(false)
      })

    }

    setOpen(false);
    if (data) {
      input = data;
      setOpenDialog(false);
      setopencircular(true)

      // window.navigator.vibrate(200);

      if (callAPI == 0) {
        callAPI = 1
        // console.log('true')
        const send = { "bid": bid, "beaconId": input,"type":'QR' }

        httpUtils({
          method: "POST",
          url: "/v1/webCheckOut",
          data: send
        }).then(response => {
          input = ""
          // console.log(input)
          // console.log(callAPI)
          setopencircular(false)
          // console.log('then')
          callAPI = 0
          // console.log(callAPI)
          // console.log(response)
          if (response.status) {

            history.push('/thankyou')
          }

          else {
            seterrormessage(response.message)
            setOpen(true);

          }
        });

      }
    }

  }
  const handleError = err => {
    // Call logs API
    console.log(err)
    if (callLogs) {
      httpUtils({
        method: "POST",
        url: "/webview/logs",
        data: { "bid": bid, "message": "Failure: Pop-up didn't opened from Checkout(product) route with no camera permission-" + err + "" }
      }).then(response => {
        // console.log(response)
        setcallLogs(false)
      })
      setshowCameraPermissionError(true)
    }
  }

  const handleClickOpenValidateCode = () => {
    setOpenDialog(false);
    setValidateCode('')
    setOpenValidateCode(true);
  };
  const handleCloseValidateCode = () => {
    setOpenValidateCode(false);
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={0}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <Backdrop className={classes.backdrop} open={opencircular}>
            <CircularProgress style={{ color: "rgb(0,109,53)" }} />
          </Backdrop>

          <div className={classes.paper}>
            <Grid container >

              <Grid container direction="column" spacing={0}>

                <Grid container direction="row">
                  {showtimer ? <Typography variant="body2" >
                    <Grid container direction="row">
                      <Grid item className={classes.nameContainer} style={{ marginLeft: "15px", marginTop: '-18px' }} xs={12}>
                        <img className={classes.image}
                          alt="watch"
                          src="/images/logos/animat-clock.gif" />
                        {/* <ScheduleOutlinedIcon style={{ fontSize: 50,color:"rgb(0,109,53)" }}/>   */}
                        {overtime ? <span style={{ color: "#8b0000" }} className={classes.createdAt}>{countertime}</span> : <span style={{ color: "rgb(0,109,53)" }} className={classes.createdAt}>{countertime}</span>}
                      </Grid>
                      {overtime ? <Grid item className={classes.date} variant="body1" gutterBottom style={{ color: "#8b0000" }} >  {text}</Grid> : <Grid item className={classes.date} variant="body1" gutterBottom style={{ color: "rgb(0,109,53)" }} >  {text}</Grid>}

                    </Grid>
                  </Typography> : null}
                </Grid>
                <Grid container >
                  {
                    Object.entries(jsonArr).map(([key, value]) => (
                      <Grid container direction="row" spacing={0} style={{ paddingLeft: "18px", marginTop: '5px' }} key={key}>
                        <Grid item xs={5} className={classes.minimizeScreen} style={{ textAlign: "left" }}>
                          <Typography style={{ color: "black", fontWeight: 800, marginLeft: '20px', marginBottom: '2px' }}>{key}:</Typography>
                        </Grid>
                        <Grid item xs={7} className={classes.minimizeScreen} style={{ textAlign: "left" }}>
                          <Typography style={{ color: "black", marginLeft: "7px", marginBottom: '2px' }}>   {value}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                </Grid>

                {/* <Grid container direction="row" spacing={0} style={{ marginTop: "15px", paddingLeft: "18px" }}>

                  <Grid item xs={5} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px' }}>Entered At:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px" }}>{starttime}</Typography>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px' }}>Exit By:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px" }}>{endtime}</Typography>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '20px' }}>Amount Paid:</Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px", fontWeight: 800 }}>{currency}{amount}</Typography>
                  </Grid>
                  <Grid item xs={5} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800 }}></Typography>
                  </Grid>
                  <Grid item xs={7} style={{ textAlign: "left", marginBottom: '15px' }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px", fontWeight: 800 }}></Typography>
                  </Grid>

                </Grid>  */}

                <Grid container direction="row" className={showtimer ? classes.bottom_but_unset_small_phn : classes.bottom_but}>







                  <Grid item xs={12} style={{ textAlign: "center" }} >
                    {showAddiText ? <div><Typography style={{ color: '#606060', textAlign: "center", fontSize: '20px', marginBottom: '0px' }} onClick={handleExtendReserv}>Extend Reservation</Typography></div> : null}
                    {showAddiText ? <div><Typography style={{ fontSize: '14px', color: "black", fontWeight: 800 }}>*Additional charges are applicable for early arrival and late checkout. The amount will be calculated while exiting.</Typography></div> :
                      <div><Typography style={{ fontSize: '13px', color: "black", fontWeight: 800 }}>*Additional charges
                    are applicable for late checkout.</Typography>
                        <Typography gutterBottom style={{ fontSize: '13px', color: "black", fontWeight: 800 }}> The amount will be calculated while exiting.</Typography></div>}

                    <Snackbar
                      open={openSnack}
                      autoHideDuration={2000}
                      onClose={handleCloseSnack}
                      style={{ display: "contents" }}
                    >
                      <Alert onClose={handleCloseSnack} severity="error">
                        {SnackErrMessage}
                      </Alert>
                    </Snackbar>
                    {showOverAmtPop ? <Grid item xs={12}><Typography style={{ color: 'gray', textAlign: "center", fontSize: '20px', marginTop: '5px', marginBottom: '5px' }} onClick={handleEstimate}>Get Estimate</Typography></Grid> : null}
                    <hr style={{ height: '0px!important', color: 'rgb(232, 232, 232)!important  ', marginBottom: '5px' }} />
                    <Button onClick={handleExitScan} variant="contained" style={{ backgroundColor: "rgb(0,109,53)", color: "white", minWidth: "270px", height: "55px", fontSize: "20px", fontWeight: 100 }}> Scan to exit</Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </div>




        </Grid>

        <Dialog onClose={handleExtendClose} disableBackdropClick aria-labelledby="customized-dialog-title" open={openExtend}>
          <Backdrop className={classes.backdrop} open={opencircular}>
            <CircularProgress style={{ color: "rgb(0,109,53)" }} />
          </Backdrop>
          <DialogTitle id="customized-dialog-title" >
            <CloseIcon onClick={handleExtendClose} style={{ float: 'right', cursor: 'pointer' }} />

          </DialogTitle>

          <DialogContent>
            {showAddiText ? <div style={{ minHeight: '180px', width: "290px" }}>
              <Typography style={{ textAlign: 'center', fontSize: "18px", fontWeight: 800, color: 'black' }}>
                Want to extend your stay?
              </Typography>

              <Typography gutterBottom style={{ marginTop: "20px", padding: '0px 10px', textAlign: 'center', color: 'gray', fontSize: '15px' }} >
                No new booking is required, you can continue your stay and the additional charges will be applied automatically at the checkout.
              </Typography>
            </div> : null}
          </DialogContent>
        </Dialog>


        <Dialog onClose={handleEstimateClose} disableBackdropClick aria-labelledby="customized-dialog-title" open={openEstimate}>
          <Backdrop className={classes.backdrop} open={opencircular}>
            <CircularProgress style={{ color: "rgb(0,109,53)" }} />
          </Backdrop>
          <DialogTitle id="customized-dialog-title" >
            <CloseIcon onClick={handleEstimateClose} style={{ float: 'right', cursor: 'pointer' }} />

          </DialogTitle>

          <DialogContent>

            {
              nocharges ?
                <div style={{ minHeight: '50px', width: "290px" }}>
                  <Typography style={{ textAlign: 'center', fontSize: "16px", fontWeight: 800, color: 'black' }}>
                    No additional charges are Applicable
                      </Typography> </div> :
                <div style={{ minHeight: '180px', width: "290px" }}>
                  <Grid container xs={12} direction="row" spacing={0} style={{ width: '310px' }}>
                    <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                      <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Entered At:</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      <Typography gutterBottom style={{ color: "black", marginLeft: "7px" }}>{additionalstarttime}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                      <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Exited At:</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                      <Typography gutterBottom style={{ color: "black", marginLeft: "7px" }}>{additionalendtime}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                      <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Additional Time:</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                      <Typography gutterBottom style={{ color: "black", marginLeft: "7px", fontWeight: 800 }}>{additionaltime}</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                      <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Additional Charges:</Typography>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                      <Typography gutterBottom style={{ color: "black", marginLeft: "7px", fontWeight: 800 }}>${overdue_amt}</Typography>
                    </Grid>
                  </Grid>
                </div>
            }

          </DialogContent>


        </Dialog>

        <Dialog onClose={handleClose} disableBackdropClick aria-labelledby="customized-dialog-title" open={open} >

          <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: 'crimson' }}>
            <ErrorOutlineIcon style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />

          </DialogTitle>
          <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>
            <DialogContent>
              <Grid xs={12} style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ fontSize: '22px', fontWeight: 600, color: '#cc0000' }}>Oh Snap!</Typography>
                <Typography gutterBottom>

                  <p style={{ fontSize: '16px', marginTop: '20px', color: '#cc0000' }}>{errormessage}</p>

                  <div style={{ marginTop: '15px' }}><Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: 'crimson' }} onClick={handleClose}>Close</Button></div>
                </Typography>
              </Grid>
            </DialogContent>
          </div>
        </Dialog>


        <Snackbar open={openCopied} autoHideDuration={1000} onClose={handleCloseCopied}>
          <Alert onClose={handleCloseCopied} severity="success">
            Copied
        </Alert>
        </Snackbar>
        <Dialog onClose={handleCloseWarning} disableBackdropClick aria-labelledby="customized-dialog-title" open={openWarning} >

          <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: 'darkorange' }}>
            <WarningRoundedIcon style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />

          </DialogTitle>
          <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>
            <DialogContent>
              <Grid style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ fontSize: '22px', fontWeight: 600, color: 'darkorange' }}>App Compatibility Issue</Typography>
                <Typography gutterBottom>

                  <p style={{ fontSize: '16px', marginTop: '20px', color: 'darkorange' }}>Please follow these additional steps to open the link in Safari</p>
                  <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                  >
                    {tutorialSteps.map((step, index) => (
                      <div key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                          <div>
                            <Typography
                              gutterBottom
                              style={{
                                marginTop: "30px",
                                marginLeft: "10px",
                                fontSize: "20px",
                                fontWeight: 800,
                                color: "rgb(0,109,53)"
                              }}
                            >
                              {step.no}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: "17px",
                                marginLeft: "10px",
                                fontWeight: 500,
                                color: "rgb(0,109,53)"
                              }}
                            >
                              {step.label}
                            </Typography>
                            <Button
                              onClick={step.func}
                              style={{
                                color: "white",
                                borderRadius: "16px",
                                marginTop: "10px",
                                width: "auto",
                                backgroundColor: "darkorange"
                              }}
                            >
                              {step.buttonText}
                            </Button>
                          </div>
                        ) :
                          null}
                      </div>
                    ))}
                  </SwipeableViews>
                  <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={
                      <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                      >
                        Next
            {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    }
                    backButton={
                      <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
            Back
          </Button>
                    }
                  />
                  {/* <div style={{ marginTop: '15px' }}><Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: 'darkorange' }} onClick={handleCloseWarning}> <CloseIcon style={{ marginRight: '5px' }} />Close</Button></div> */}
                </Typography>

              </Grid>
            </DialogContent>
          </div>
        </Dialog>

        <Dialog
          disableBackdropClick
          open={openValidateCode}
          onClose={handleCloseValidateCode}
          aria-labelledby="alert-dialog-title"
        >
          <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}> <h2>Enter the code affixed to the gate</h2></DialogTitle>

            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    // error={validateCode.length<1}
                    autoFocus
                    variant="outlined"
                    id="outlined-code"
                    autoComplete='off'
                    value={validateCode}
                    label="Enter Code"
                    type="text"
                    onChange={handleValidateCode}
                    fullWidth
                    // helperText={validateCode.length<1?'Empty field!':''}
                    margin="normal"

                  />
                </Grid>
                <Grid item xs={12} style={{ textAlign: "center", marginTop: '30px' }}>
                  <Button onClick={handleSendValidateCode} disabled={validateCode.length < 1} variant="contained" className={validateCode.length >= 1 ? classes.suc_btn : classes.def_btn} style={{ width: "100px", fontSize: "18px", fontWeight: 100 }}>Send</Button>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center", marginTop: '15px' }}>
                  <Button onClick={handleCloseValidateCode} style={{ width: "100px", fontSize: "17px", fontWeight: 100 }}>Close</Button>
                </Grid>
              </Grid>
            </DialogContent>

          </div>
        </Dialog>

        <Dialog onClose={handleCloseDialog} disableBackdropClick aria-labelledby="customized-dialog-title" open={openDialog} >
          {showCameraPermissionError ? <DialogTitle id="customized-dialog-title" style={{ textAlign: 'center', margin: '0px!important', padding: '8px 8px', backgroundColor: 'crimson' }}>
            <ErrorOutlineIcon style={{ cursor: 'pointer', fontSize: '40px', color: 'white' }} />

          </DialogTitle> : <DialogTitle id="customized-dialog-title" style={{ margin: '0px!important' }}>
            <CloseIcon onClick={handleCloseDialog} style={{ float: 'right', cursor: 'pointer' }} />

          </DialogTitle>}

          <DialogContent >
            {showCameraPermissionError ? <div style={{ width: '285px', minheight: '130px', padding: '10px ' }}>

              <Grid style={{ textAlign: 'center' }}>
                <Typography gutterBottom style={{ fontSize: '22px', fontWeight: 600, color: '#cc0000' }}>Oh Snap!</Typography>
                <Typography gutterBottom>

                  <p style={{ fontSize: '16px', marginTop: '20px', color: '#cc0000' }}>Camera access is denied</p>

                  <div style={{ marginTop: '15px' }}><Button style={{ color: 'white', borderRadius: '16px', width: '100px', backgroundColor: 'crimson' }} onClick={handleCloseDialog}>Close</Button></div>
                </Typography>
                {showIOSCameraLink ? <Typography gutterBottom style={{ marginTop: "35px", textAlign: 'center', fontSize: "14px", color: 'gray' }}>
                  <a target='_blank' href='https://support.apple.com/en-in/guide/iphone/iph168c4bbd5/ios'><u style={{ color: 'gray' }}>Read more to know how to allow the camera permission.</u></a>
                </Typography> : <Typography gutterBottom style={{ marginTop: "35px", textAlign: 'center', fontSize: "14px", color: 'gray' }}>
                  <a target='_blank' href='https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid&hl=en&oco=1'><u style={{ color: 'gray' }}>Read more to know how to allow the camera permission.</u></a>
                </Typography>}


              </Grid>
              {/* <Grid item xs={12} style={{ textAlign: "center", marginTop: '15px' }}>
                <Button onClick={handleClickOpenValidateCode} style={{ fontSize: "16px", fontWeight: 800, color: 'rgb(25, 118, 210)' }}>
                  Enter via Code
                </Button>
              </Grid> */}
            </div> :
              <div>

                <QrReader
                  delay={250}
                  onError={handleError}
                  onScan={handleScan}

                  style={{
                    position: "initial!important",
                    border: "2px rgba(0,0,0,0.3)",
                    width: "310px",
                    height: "345px"
                  }}
                />


                {additionalData ? <Grid container xs={12} direction="row" spacing={0} style={{ width: '310px' }}>
                  <Grid container direction='row' xs={12}>
                    <Grid container xs={8} direction="column">
                      <Grid item>
                        <Typography style={{ marginLeft: '10px', color: 'gray' }}>
                          Scan the QR code affixed
                </Typography>
                        <Typography style={{ marginLeft: '10px', color: 'gray' }}>
                          to the exit machine
                </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={4} direction="column">
                      <Grid item style={{ textAlign: 'center' }}>
                        <img
                          alt="Logo"
                          src="/images/qr-code.png" />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Button onClick={handleClickOpenValidateCode}style={{ fontSize: "16px", fontWeight: 800, color: 'rgb(25, 118, 210)' }}>
                      Have validation code?
                </Button>
                  </Grid> */}
                  <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Entered At:</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left" }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px" }}>{additionalstarttime}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Exited At:</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px" }}>{additionalendtime}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Additional Time:</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px", fontWeight: 800 }}>{additionaltime}</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                    <Typography gutterBottom style={{ color: "black", fontWeight: 800, marginLeft: '10px' }}>Additional Charges:</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "left", marginBottom: '5px' }}>
                    <Typography gutterBottom style={{ color: "black", marginLeft: "7px", fontWeight: 800 }}>${overdue_amt}</Typography>
                  </Grid>

                </Grid> :
                  <Grid>
                    <Grid container direction='row' xs={12}>
                      <Grid container xs={8} direction="column">
                        <Grid item>
                          <Typography style={{ marginLeft: '10px', color: 'gray' }}>
                            Scan the QR code affixed
                           </Typography>
                          <Typography style={{ marginLeft: '10px', color: 'gray' }}>
                            to the exit machine
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container xs={4} direction="column">
                        <Grid item style={{ textAlign: 'center' }}>
                          <img
                            alt="Logo"
                            src="/images/qr-code.png" />
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid item style={{ textAlign: 'center' }}>
                  <Button onClick={handleClickOpenValidateCode} style={{ fontSize: "16px", fontWeight: 800, color: 'rgb(25, 118, 210)' }}>
                    Exit via Code
                </Button>
                </Grid> */}
                  </Grid>

                }
              </div>}
          </DialogContent>


        </Dialog>
      </Grid>

    </div >

  );
};
ProductList.propTypes = {

  history: PropTypes.object,
};
export default ProductList;
