import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import httpUtils from '../../../../common/httpUtils';
import moment from 'moment';
import HelpSharpIcon from '@material-ui/icons/HelpSharp';
import {
  Button,
  Grid,

  Typography,

} from '@material-ui/core';
import { setSyntheticLeadingComments } from 'typescript';
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  toggle: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  papergreencard: {



    backgroundColor: "#F2F4F8"

  },
  imageouterdiv: {
    position: "relative!important",

  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "250px",
    backgroundColor: 'rgb(0,109,53)'


  },
  image1: {
    objectFit: "cover",
    width: "100%",
    height: "196px",
    backgroundColor: 'rgb(0,109,53)'


  },
  textblock: {
    position: "absolute!important",
    top: "15px",
    width: "100%",

    color: "white",
  },
  number: {

    color: "#9ABBA8", ['@media (max-width:370px)']: { fontSize: "13px", color: "#9ABBA8", lineHeight: "16px" }
  },
  phone: {
    color: "white", fontWeight: 200, paddingBottom: "10px", ['@media (max-width:370px)']: { fontSize: "18px", color: "white", fontWeight: 300, lineHeight: "17px" }
  },
  addcard: {
    fontSize: "25px", marginLeft: '22px', color: 'white', fontWeight: 100,
    ['@media (max-width:370px)']: {
      fontSize: "20px", marginLeft: '22px', color: 'white', fontWeight: 100,
    }, ['@media (min-width:400px)']: {
      fontSize: "25px", marginLeft: '22px', color: 'white', fontWeight: 100,
    }
  },
  addcardthankyou: {
    fontSize: "32px", marginLeft: '25px', color: 'white', fontWeight: 100,
    ['@media (max-width:370px)']: {
      fontSize: "30px", marginLeft: '25px', color: 'white', fontWeight: 100,
    }, ['@media (min-width:400px)']: {
      fontSize: "32px", marginLeft: '25px', color: 'white', fontWeight: 100,
    }
  },
  addcardcheck: {
    fontSize: "15px", marginLeft: '34px', color: 'white', fontWeight: 100,
    ['@media (max-width:370px)']: {
      fontSize: "13px", marginLeft: '34px', color: 'white', fontWeight: 100,
    }, ['@media (min-width:400px)']: {
      fontSize: "15px!important", marginLeft: '34px', color: 'white', fontWeight: 100,
    }
  },
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const [exit, setexit] = React.useState(true)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  var bid = (localStorage.getItem('bid'))
  var loc = window.location.href
  const [bookingType, setbookingType] = React.useState('');
  const [checkin, setcheckintime] = React.useState('')
  const [checkindate, setcheckindate] = React.useState('')
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [garagename, setgaragename] = React.useState('');
  React.useEffect(() => {
    httpUtils({
      method: "GET",
      url: "v1/userConfigByBID/"+bid,
    }).then((response) => {

        if (response.status) {
          setbookingType(response['data']['state']['booking_type'])
        }
        else { 
          console.log(response.message)
        } 
      },(error)=>{
        console.log(error)
      })

    // console.log(locationsplit) 
  }, [])


  return (
    <div className={classes.papergreencard} >
      <Grid container spacing={0} >
        <Grid item xs={12} >

          <div className={classes.imageouterdiv}>
            <div className={classes.image1} />
            <Grid item xs={12}>
              <div className={classes.textblock}>

              {bookingType == '3' ?
                  //hotel reservation link
                <Grid container>
                  <Grid item xs={9}>

                    <IconButton
                      color="inherit"
                      onClick={onSidebarOpen}
                      className={classes.toggle}
                    >
                      {/* <MenuIcon /> */}
                    </IconButton>


                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right", marginTop: "32px" }}>
                    <Button component="a" target='_blank' style={{ color: 'white' }}
                      href="https://pialleygarage.com/faq-reservation/">
                      <HelpSharpIcon style={{ fontSize: 35 }} />
                    </Button>
                  </Grid>
                </Grid>:
                   <Grid container>
                   <Grid item xs={9}>

                     <IconButton
                       color="inherit"
                       onClick={onSidebarOpen}
                       className={classes.toggle}
                     >
                       {/* <MenuIcon /> */}
                     </IconButton>


                   </Grid>
                   <Grid item xs={3} style={{ textAlign: "right", marginTop: "32px" }}>
                     {/* <Button component="a" target='_blank' style={{ color: 'white' }}
                    href="https://pialleygarage.com/faq-reservation/">
                    <HelpSharpIcon style={{ fontSize: 35 }} />
                  </Button> */}
                   
                   </Grid>
                 </Grid>
               }
               
               {bookingType == '3' ? 
                  <Grid container>
                  <Grid item xs={9}>
                  </Grid>
                  <Grid item xs={3} style={{ textAlign: "right", marginTop: "5px", padding: "0px 15px" }}>
                    <a href='https://pialleygarage.com/contact-us/' target='_blank'> <img
                      alt="watch"
                      src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px', marginLeft: '4px' }} /></a>
                  </Grid> 
                    </Grid> :
                  <Grid container>
                    <Grid item xs={9}>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: "right", marginTop: "43px", padding: "0px 15px" }}>
                      <a href='https://pialleygarage.com/contact-us/' target='_blank'> <img
                        alt="watch"
                        src="/images/logos/call-center-agent (1).png" style={{ textAlign: "left", height: '29px', width: '29px', marginLeft: '4px' }} /></a>
                    </Grid>
                  </Grid>
                }

                <Grid container>
                  <Grid item xs={12} style={{ marginTop: "30px" }}>

                    <Typography className={classes.addcardthankyou} gutterBottom> Thank you! </Typography>
                  </Grid>
                </Grid>



              </div>
            </Grid>
          </div>
        </Grid>
      </Grid>





    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,

};

export default Topbar;
