import React,{ lazy, Suspense } from 'react';
import { Switch, Redirect} from 'react-router-dom';
import { RouteWithLayout ,RouteWithoutLayout} from './components';
import { Main as MainLayout, Minimal as MinimalLayout, Minimize as MinimizeLayout} from './layouts';
import { withRouter } from "react-router";
import { 
  Dashboard as DashboardView,
  ProductList as ProductListView,

  Typography as TypographyView,
  Cancel as CancelView,

  Settings as SettingsView,
  NotFound as NotFoundView,
} from './views';



const Routes = () => {

  return (
    <Switch>
     {/* <Route path='/' component={SettingsView}/> */}
      {/* <Redirect
        exact
        from="/"
        to="/settings"
      /> */}
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
     
     
      <RouteWithLayout
        component={ProductListView}
        exact
        layout={MinimalLayout}
        path="/estimate"
      />
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MinimizeLayout}
        path="/thankyou"
      />
       <RouteWithLayout
        component={CancelView}
        exact
        layout={MinimizeLayout}
        path="/cancelled"
      />

      <RouteWithoutLayout
        component={SettingsView}
        exact
     
        path="/"
      />
    
      <RouteWithoutLayout
        component={NotFoundView}
        exact
       
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default withRouter(Routes);
