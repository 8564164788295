import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import PropTypes from 'prop-types';
import httpUtils from '../../common/httpUtils'
import queryString from 'query-string';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  }
}));

const Settings = (props) => {
  const classes = useStyles();
  const { history } = props;
  // var UID;


 
  React.useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    // console.log(parsed);
    // console.log(parsed.PIA);
    
    localStorage.setItem('bid', parsed.PIA)
    localStorage.setItem('DailogState',true)
    
    // let url = "https://pialleyapi-dev.divrt.co/api/v1/userConfigByBID/" + parsed.PIA
    // fetch(url).then((response) => {
    //   response.json().
      httpUtils({
        method: "GET",
        url: "/v1/userConfigByBID/" + parsed.PIA,
  
      }).then((response) => {
        
        if (response.status) {
          // UID=(response['data']['user']['uid'])
          // console.log(UID)

     
          // sessionStorage.setItem('UID', response['data']['user']['uid'])
          if(response['data']['state']['state']=='0'){
            
           console.log(history)
            history.push({pathname:'/dashboard', state: { detail: parsed.PIA }})
            // history.push('/dashboard')
          }
          if (response['data']['state']['state'] == '8' || response['data']['state']['state'] == '10') {
            history.push('/thankyou')
          }
          else if (response['data']['state']['state'] == '2') {
            // console.log(history)
            history.push('/estimate')
       
           
          }
          if (response['data']['state']['state'] == '-1') {
            history.push('/not-found')
          }
          if (response['data']['state']['state'] == '-99') {
            history.push('/cancelled')
          }
           if (response['data']['state']['state'] == '99') {
            history.push('/cancelled')
          } 
        }else{
          httpUtils({
            method: "POST",
            url: "/webview/logs",
            data: { "bid": "null", "message": "ID not present in the URL of settings route in the first API call" }
          }).then(response => {
            // console.log(response)
      
         })
         if(response.message=='Invalid Booking Id'){
            history.push('/not-found')
          }
        }
          // if(response.message=='Invalid Booking Id'){
          //   history.push('/not-found')
          // }
      }).catch(err => {
        // setmode(false)
        console.log('error')
      
        // let collect = localStorage.getItem('usersdata')
        // var storeddata = JSON.parse(collect)
    })

    // history.push('/dashboard')
    
    // console.log(window.navigator.onLine)
    
  }, [])


  return (
    <div className={classes.root}>


    
    </div>
  ); 
};


Settings.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};
export default Settings;