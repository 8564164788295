import React, { Component, useEffect } from 'react';
import { Router ,Route} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { ThemeProvider } from '@material-ui/styles';


import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import Routes from './Routes';

const browserHistory = createBrowserHistory();



export default class App extends Component {
  constructor(props) {
    super(props);

    // Store the previous pathname and search strings
    this.currentPathname = null;
    this.currentSearch = null;
  
    this.state = { windowWidth: window.innerWidth ,
      isMobile: true
    };
  }

   handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
    if(window.innerWidth>=1000){
    this.setState({isMobile:false})
    }
    if(window.innerWidth<1000){
      this.setState({isMobile:true})
      }
   };

  //  componentWillUnmount() {
  //   window.addEventListener("resize", this.handleResize);
  //  }

  componentDidMount() {
    if(window.innerWidth>1000){
      this.handleResize()
      // console.log('height')
    }
    window.addEventListener("resize", this.handleResize);
    const { history } = this.props;
    // console.log(this.props);
    history.listen((newLocation, action) => {
      if (action === "PUSH") {
        if (
          newLocation.pathname !== this.currentPathname ||
          newLocation.search !== this.currentSearch
        ) {
          // Save new location
          this.currentPathname = newLocation.pathname;
          this.currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });

    
     
    
  }
  
  render() {
    const { windowWidth } = this.state; 
    const {isMobile}=this.state;
  //  console.log(windowWidth)
  //  console.log(isMobile)
  //  if(windowWidth>1000){
  //    this.setState({isMobile:false})
  //  }
    return (
  <div> {isMobile?<ThemeProvider theme={theme}>
    <Router history={browserHistory}>
      <Routes />
     
    </Router>
  </ThemeProvider>:<div style={{border: '40px solid #ddd',
    borderWidth: '55px 7px',
    borderRadius: '40px',
    margin: '50px auto',
    overflow: 'hidden',
    transition: 'all 0.5s ease',
    width: '370px',
    height: 'auto',
    paddingBottom:'15px'}}>
    
      <ThemeProvider theme={theme}>
        <Router history={browserHistory}>
          <Routes />
         
        </Router>
      </ThemeProvider>
    </div>
    }
    </div> 
    );
  }
}
